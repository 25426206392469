'use strict';

(function ($) {
	angular.module('simpleMap').directive('simpleMap', function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Get the first element with data-map-viewport attribute.
				var mapViewportElement = element.find('[data-map-viewport]')[0],
				    properties = scope.$eval($(mapViewportElement).data('map-viewport'));

				// Use Yandex map as default.
				if (!properties.type) properties.type = 'yandex';

				var map = void 0;

				switch (properties.type.toLowerCase()) {
					case 'google':

						// Map position.
						var pos = new google.maps.LatLng(properties.latitude, properties.longitude);

						// Map options.
						var mapProp = {
							scrollwheel: properties.scrollwheel || false, // Disable middle mouse button zooming.
							mapTypeControl: properties.mapTypeControl || false,
							zoomControl: properties.zoomControl || true,
							zoomControlOptions: {
								position: properties.zoomControlOptions && properties.zoomControlOptions.position || google.maps.ControlPosition.LEFT_CENTER
							},
							streetViewControl: properties.streetViewControl || false,
							scaleControl: properties.scaleControl || false,
							center: properties.center || pos,
							zoom: properties.zoom || 16,
							mapTypeId: properties.mapTypeId || google.maps.MapTypeId.ROADMAP
						};

						// Init the map by using first element with data-map attribute.
						map = new google.maps.Map(mapViewportElement, mapProp);

						// Create and show the markers.
						var markersElements = element.find('[data-map-marker]');
						for (var i = 0; i < markersElements.length; ++i) {
							var contentString = $(markersElements[i]).html(),
							    infowindow = new google.maps.InfoWindow({
								content: contentString
							}),
							    markerProperties = scope.$eval($(markersElements[i]).data('map-marker')),
							    marker = new google.maps.Marker({
								position: markerProperties && markerProperties.latitude && markerProperties.longitude ? new google.maps.LatLng(markerProperties.latitude, markerProperties.longitude) : pos,
								map: map,
								draggable: markerProperties && markerProperties.draggable || false
							});

							// Open baloon if needed.
							if (markerProperties && markerProperties.openBalloon) infowindow.open(map, marker);
						}

						// Remove markers elements after processing.
						$(markersElements).remove();

						break;

					case 'yandex':

						// Get id of the first element with data-map attribute or create it if not exists.
						if (!mapViewportElement.id) {
							mapViewportElement.id = 'map';
						}

						// Set map init function.
						ymaps.ready(function init() {
							// Map position.
							var pos = [properties.latitude, properties.longitude];

							// Create map on the element with the specified id.
							map = new ymaps.Map(mapViewportElement.id, {
								center: properties.center || pos,
								zoom: properties.zoom || 16,
								controls: ['zoomControl']
							});

							// Disable middle mouse button zooming.
							map.behaviors.disable('scrollZoom');

							// Create and show the markers (placemarks).
							var markersElements = element.find('[data-map-marker]');
							for (var _i = 0; _i < markersElements.length; ++_i) {
								var _contentString = $(markersElements[_i]).html(),
								    _markerProperties = scope.$eval($(markersElements[_i]).data('map-marker')),
								    markerPosition = _markerProperties && _markerProperties.latitude && _markerProperties.longitude ? [_markerProperties.latitude, _markerProperties.longitude] : pos,
								    _marker = new ymaps.Placemark(markerPosition, {
									balloonContent: _contentString
								}, {
									draggable: _markerProperties && _markerProperties.draggable || false
								});

								map.geoObjects.add(_marker);

								// Open baloon if needed.
								// NOTE: Yandex map can't open several balloons simultaneously by design.
								//       Therefore if several markers have openBalloon: true property value, only last processed marker balloon will be shown.
								if (_markerProperties && _markerProperties.openBalloon) _marker.balloon.open();
							}

							// Remove markers elements after processing.
							$(markersElements).remove();
						});

						break;

					default:
						throw new Error('Map type ' + properties.type + ' is not supported by the map-section AngularJS 1 module.');
				}
			}
		};
	});
})(jQuery);