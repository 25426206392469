'use strict';

angular.module('app').controller('ProductDetailController', ['__', '$q', '$state', '$scope', '$rootScope', '$stateParams', '$sce', '$interpolate', 'UriConfig', 'OrmProduct', 'ShoppingCartService', 'NavigationService', 'ngMeta', 'ngMetaTagTemplates', function (__, $q, $state, $scope, $rootScope, $stateParams, $sce, $interpolate, UriConfig, OrmProduct, ShoppingCartService, NavigationService, ngMeta, ngMetaTagTemplates) {
	// Если идентификатор не указан, то возвращаем в список новостей
	if (!$stateParams.id) {
		$scope.promiseList.push($state.go('404', {
			errorUrl: window.location
		}));
		return;
	}

	var productItemPrice = 0;

	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	// Переменные в scope
	$scope.productItem = {};
	$scope.imageList = undefined;
	$scope.propertiesOptions = undefined;
	$scope.quantity = 1;

	$scope.methods = {};

	var configure = function configure() {
		return $q(function (resolve, reject) {
			OrmProduct.view({
				id: function () {
					return UriConfig.useSlugifyName ? undefined : $stateParams.id;
				}(),
				filter: {
					where: function () {
						return UriConfig.useSlugifyName ? {
							slugifyName: $stateParams.id
						} : undefined;
					}(),
					include: [{
						relation: 'images',
						scope: {
							where: {
								sizeId: 'original'
							}
						}
					}, {
						relation: 'features',
						scope: {
							include: 'extraProperties',
							order: ['priority ASC', 'name ASC']
						}
					}, 'specificationItems', 'htmlMetaTags']
				}
			}).$promise.then(function (result) {
				var productItem = result;
				if (!productItem) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
					return resolve() || null;
				}

				productItemPrice = productItem.price || 0;

				// Устанавливаем мета теги в соответствии с настройками
				var htmlMetaTagTitleExist = false;
				var htmlMetaTagDescriptionExist = false;

				var htmlMetaTagList = productItem && productItem.htmlMetaTags || [];
				if (htmlMetaTagList && htmlMetaTagList.length > 0) {
					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = htmlMetaTagList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var htmlMetaTag = _step.value;

							if (htmlMetaTag.name === 'title') {
								ngMeta.setTitle(htmlMetaTag.content);
								ngMeta.setTag('og:title', htmlMetaTag.content);
								htmlMetaTagTitleExist = true;
							} else if (htmlMetaTag.name === 'description') {
								ngMeta.setTag('description', htmlMetaTag.content);
								ngMeta.setTag('og:description', htmlMetaTag.content);
								htmlMetaTagDescriptionExist = true;
							} else {
								ngMeta.setTag(htmlMetaTag.name, htmlMetaTag.content);
							}
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}
				}

				if (productItem && !htmlMetaTagTitleExist) {
					var titleTag = $interpolate(ngMetaTagTemplates.productTitleTemplate)({
						product: productItem
					});
					ngMeta.setTitle(titleTag);
					ngMeta.setTag('og:title', titleTag);
				}
				if (productItem && !htmlMetaTagDescriptionExist) {
					var descriptionTag = $interpolate(ngMetaTagTemplates.productDescriptionTemplate)({
						product: productItem
					});
					ngMeta.setTag('description', descriptionTag);
					ngMeta.setTag('og:description', descriptionTag);
				}

				$scope.productItem = productItem;
				$scope.productItem.description = $sce.trustAsHtml(productItem.description);
				$scope.navigation = NavigationService.getHistoryByParent({
					id: productItem.parentId
				});

				// Изображения для галереи изображений.
				productItem.images = __.chain(productItem.images).forEach(function (value) {
					value.isPrimaryImage = value.id === productItem.primaryImageId;
				}).orderBy(['isPrimaryImage', 'priority'], ['desc', 'asc']).value();
				var imageList = productItem.images;
				if (imageList && imageList.length > 0) {
					var buffer = [];
					var _iteratorNormalCompletion2 = true;
					var _didIteratorError2 = false;
					var _iteratorError2 = undefined;

					try {
						for (var _iterator2 = imageList[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
							var image = _step2.value;

							buffer.push({
								id: image.id,
								url: '/api/images/' + image.id + '/large/download'
							});
						}
					} catch (err) {
						_didIteratorError2 = true;
						_iteratorError2 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion2 && _iterator2.return) {
								_iterator2.return();
							}
						} finally {
							if (_didIteratorError2) {
								throw _iteratorError2;
							}
						}
					}

					$scope.imageList = buffer;
				}

				return resolve() || null;
			}).catch(function (err) {
				if (err.status === 404) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
				} else {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
				}
				return reject(err);
			});
		});
	};

	$scope.onFeatureChange = function (value) {
		$scope.productItem.feature = value;
		$scope.productItem.price = __.get(value, 'price', productItemPrice);
	};

	//scope
	$scope.increaseQuantity = function (q) {
		if ($scope.quantity + q > 0) {
			$scope.quantity += q;
		}
	};

	$scope.addToShoppingCart = function () {
		if (!$scope.productItem.price) {
			return $rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Цена товара не задана')
			});
		}

		ShoppingCartService.createOrUpdateElement({
			product: $scope.productItem,
			quantityOffset: $scope.quantity,
			feature: $scope.productItem.feature,
			price: $scope.productItem.price
		});
	};

	$scope.quantityInCart = function () {
		var quantity = 0;
		if ($scope.productItem) {
			var shoppingCartItem = ShoppingCartService.getElement($scope.productItem, $scope.productItem.feature);
			quantity = shoppingCartItem && shoppingCartItem.quantity || 0;
		}
		return quantity;
	};

	$scope.$on('login', function (event, data) {
		$scope.promiseList.push(configure());
	});

	$scope.$on('logout', function (event) {
		$scope.promiseList.push(configure());
	});

	$scope.$on('onBeforeTransition', function (event) {
		$scope.methods.close();
	});

	$scope.promiseList.push(configure());
}]);