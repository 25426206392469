'use strict';

angular.module('lodash').provider('__', function ($windowProvider) {
	var $window = $windowProvider.$get();

	return {
		$get: function $get() {
			if (!$window._) {
				throw new Error('Библиотека lodash не подключена');
			}
			return $window._;
		}
	};
});