'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	var COMPONENT_NAME = 'nwProductSpecificationFilter';

	angular.module('natiwi.product-specification-filter').component(COMPONENT_NAME, {
		template: '<div ng-include="$ctrl.getTemplate()">',
		bindings: {
			specificationPropertyList: '<',
			specificationValueItemList: '<',
			onSpecificationChange: '=',
			dontUseCdn: '<'
		},
		controller: function controller($q, __, cdn) {
			var ctrl = this;

			ctrl.submitTitle = 'Показать';
			ctrl.specificationItemList = null;
			var multipleOptionFnCache = null;

			var configure = function configure() {
				multipleOptionFnCache = {};
				ctrl.specificationItemList = __.chain(ctrl.specificationPropertyList).map(function (value) {
					var property = value.property;
					if (!property) {
						return null;
					}
					var values = property.values;
					if (property.propertyTypeId === 'value_metadata_types.relation' && (!values || values.length === 0)) {
						return null;
					}
					return {
						property: property,
						values: values,
						value: function () {
							var result = null;

							var defaultValue = __.chain(ctrl.specificationValueItemList).find({
								propertyId: property.id
							}).get('valueId').value();

							if (__.isNil(defaultValue)) {
								return null;
							}

							if (property.values && property.values.length > 0 && property.propertyTypeId === 'value_metadata_types.relation') {
								result = __.filter(property.values, function (value) {
									return defaultValue.indexOf(value.id) >= 0;
								});
							} else {
								result = defaultValue;
							}

							return result;
						}()
					};
				}).value();
			};

			ctrl.createMultipleOptionFn = function (specificationItem, value) {
				var valueId = value;
				if (specificationItem.property.propertyTypeId === 'value_metadata_types.relation') {
					valueId = value.id;
				}
				var key = '' + specificationItem.property.id + valueId;
				var fn = multipleOptionFnCache[key];
				if (!fn) {
					fn = function fn(checked) {
						var valueList = specificationItem.value;
						if (valueList == null) {
							valueList = [];
							specificationItem.value = valueList;
						}
						if (arguments.length === 0) {
							return valueList.indexOf(value) > -1;
						} else {
							var index = valueList.indexOf(value);
							if (checked) {
								if (index === -1) {
									valueList.push(value);
								}
							} else {
								if (index > -1) {
									valueList.splice(index, 1);
								}
							}
						}
					};
					multipleOptionFnCache[key] = fn;
				}
				return fn;
			};

			ctrl.submit = function () {
				return $q(function (resolve, reject) {
					if (!ctrl.specificationItemList) {
						return reject(new Error('Фильтры не заданы'));
					}
					if (ctrl.specificationItemList.length === 0) {
						return reject(new Error('Фильтры пустые'));
					}
					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = ctrl.specificationItemList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var specificationItem = _step.value;

							var property = specificationItem.property;
							if (property.required) {
								if (!specificationItem.value) {
									return reject(new Error('\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \'' + property.name + '\' \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u043E'));
								}
							}
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}

					var eventData = __.chain(ctrl.specificationItemList).map(function (value) {
						var specificationProperty = value.property;
						var specificationValue = value.value;
						if (__.isNil(specificationValue) || specificationValue === '') {
							return null;
						}
						var specificationRelationValue = undefined;
						if (specificationProperty.propertyTypeId === 'value_metadata_types.relation') {
							if (specificationValue.length === 0) {
								return null;
							}
							specificationRelationValue = __.map(specificationValue, 'id');
						}
						return {
							//property: specificationProperty,
							propertyId: specificationProperty.id,
							//value: specificationValue,
							valueType: specificationProperty.propertyTypeId,
							valueId: specificationRelationValue || specificationValue,
							valueRelationType: specificationProperty.propertyRelationType,
							valueRelationValue: specificationRelationValue || specificationValue
						};
					}).compact().value();

					ctrl.onSpecificationChangeEvent(eventData);

					return resolve() || null;
				});
			};

			ctrl.$onChanges = function (changesObj) {
				var performConfigure = false;
				var specificationPropertyListChanges = changesObj.specificationPropertyList;
				if (specificationPropertyListChanges && specificationPropertyListChanges.currentValue !== specificationPropertyListChanges.previousValue) {
					performConfigure = true;
				}
				var specificationItemListChanges = changesObj.specificationItemList;
				if (specificationItemListChanges && specificationItemListChanges.currentValue !== specificationItemListChanges.previousValue) {
					performConfigure = true;
				}
				if (performConfigure) {
					configure();
				}
			};

			ctrl.onSpecificationChangeEvent = function (value) {
				if (ctrl.onSpecificationChange) {
					ctrl.onSpecificationChange(value);
				}
			};

			configure();

			//поддержка кастомных шаблонов
			ctrl.getTemplate = function () {
				var cdnUrl = '';
				if (!ctrl.dontUseCdn) {
					cdnUrl = cdn.url;
				}
				return cdnUrl + 'core/shared/nw-product-specification-filter/nw-product-specification-filter.template.html';
			};

			return ctrl;
		}
	});
})();