'use strict';

angular.module('app').value('NewsListControllerConfig', {
	limitList: [{
		name: '15',
		value: 15
	}, {
		name: '25',
		value: 25
	}, {
		name: '50',
		value: 50
	}, {
		name: '75',
		value: 75
	}, {
		name: '100',
		value: 100
	}],
	sortList: [{
		name: 'Сначала новые',
		value: 'createdAt DESC'
	}, {
		name: 'Сначала старые',
		value: 'createdAt ASC'
	}]
}).provider('NewsListController.Config', function () {
	var options = {};
	this.limitList = function (limitList) {
		return options.limitList = limitList;
	};
	this.sortList = function (sortList) {
		return options.sortList = sortList;
	};
	this.$get = ['NewsListControllerConfig', function (NewsListControllerConfig) {
		var tempOptions = NewsListControllerConfig;
		for (var prop in options) {
			if (angular.isObject(options[prop]) && !angular.isArray(options[prop])) {
				angular.extend(tempOptions[prop], options[prop]);
			} else {
				tempOptions[prop] = options[prop];
			}
		}

		return tempOptions;
	}];
}).controller('NewsListController', ['$scope', '$rootScope', '$state', '$q', '$stateParams', 'NewsListController.Config', 'OrmNewsMetadata', function ($scope, $rootScope, $state, $q, $stateParams, NewsListControllerConfig, OrmNewsMetadata) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	// Переменные в scope
	$scope.newsList = undefined;
	$scope.context = {};
	$scope.context.newsListTotalCount = 0;

	function configure() {
		return $q(function (resolve, reject) {
			OrmNewsMetadata.viewList({
				filter: {
					limit: function () {
						return $scope.limitOptions.value && $scope.limitOptions.value.value;
					}(),
					skip: function () {
						var limitValue = $scope.limitOptions.value && $scope.limitOptions.value.value || 0;
						var pageValue = $scope.pageOptions.value || 0;
						return (pageValue - 1) * limitValue;
					}(),
					order: function () {
						return $scope.sortOptions.value && $scope.sortOptions.value.value;
					}(),
					include: ['author', 'thumb']
				},
				params: {
					elementType: 0
				},
				// Для совместимости с более старыми версиями бэкэнда
				options: {
					elementType: 0
				}
			}).$promise.then(function (result) {
				var output = result && result.output || [];
				var outputInfo = result && result.outputInfo || {
					totalCount: output.length
				};

				$scope.newsList = output;
				$scope.context.newsListTotalCount = outputInfo.totalCount || 0;

				var limit = $scope.limitOptions.value && $scope.limitOptions.value.value || 0;

				var pageOptions = [];
				var pageCount = limit === 0 ? 1 : Math.max(Math.ceil($scope.context.newsListTotalCount / limit), 1);
				for (var i = 1; i <= pageCount; ++i) {
					pageOptions.push(i);
				}
				$scope.pageOptions.values = pageOptions;
				if ($scope.pageOptions.value > pageCount) {
					$scope.pageOptions.value = pageCount;
				}

				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
	};

	// Страница.
	var page = $stateParams.page || 1;
	page = Math.max(parseInt(page), 1);
	if (isNaN(page)) {
		page = 1;
	}

	$scope.pageOptions = {
		value: page,
		values: [1]
	};
	// Проверяем выход текущей страницы за границы доступных страниц
	$scope.$watch('pageOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.pageOptions.value = Math.max(Math.min(newValue, $scope.pageOptions.values.length + 1), 0);
			$scope.promiseList.push(configure());
		}
	});

	// Доступные ограничения количества товаров на странице.
	var defaultLimits = NewsListControllerConfig.limitList || [];

	// Переменная limit хранит объект, описывающий текущее ограничение количества товаров на странице.
	// По умолчанию это первый объект из массива доступных ограничений.
	var limit = undefined;
	if (defaultLimits && defaultLimits.length > 0) {
		limit = defaultLimits[0];
	}

	// Если при переходе на состояние списка товаров был указан параметр ограничения товаров на странице, применить его.
	if ($stateParams.limit) {
		var paramLimit = Number($stateParams.limit);
		for (var key in defaultLimits) {
			if (defaultLimits[key].value === paramLimit) {
				limit = defaultLimits[key];
				break;
			}
		}
	}

	// Объект настройки ограничения количества товаров на странице.
	$scope.limitOptions = {
		value: limit,
		values: defaultLimits
	};

	// Обработчик изменения ограничения.
	$scope.$watch('limitOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.promiseList.push($q(function (resolve, reject) {
				$state.go('news.list', {
					page: undefined,
					limit: newValue.value
				}).then(function (result) {
					return configure();
				}).then(function (result) {
					return resolve() || null;
				}).catch(function (err) {
					return reject(err);
				});
			}));
		}
	});

	// Сортировка.

	// Доступные виды сортировки списка.
	var defaultSorts = NewsListControllerConfig.sortList || [];

	// Переменная sort хранит объект, описывающий текущую сортировку.
	// По умолчанию это первый объект из массива доступных видов сортировки списка.
	var sort = undefined;
	if (defaultSorts && defaultSorts.length > 0) {
		sort = defaultSorts[0];
	}

	// Если при переходе на состояние списка товаров был указан параметр сортировки, применить её.
	if ($stateParams.sort) {
		for (var _key in defaultSorts) {
			if (defaultSorts[_key].value === $stateParams.sort) {
				sort = defaultSorts[_key];
				break;
			}
		}
	}

	// Объект настройки сортировки.
	$scope.sortOptions = {
		value: sort, // Текущая сортировка.
		values: defaultSorts // Все виды сортировок.
	};

	// Обработчик изменения сортировки.
	$scope.$watch('sortOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.promiseList.push($q(function (resolve, reject) {
				$state.go('news.list', {
					page: undefined,
					sort: newValue.value
				}).then(function (result) {
					return configure();
				}).then(function (result) {
					return resolve() || null;
				}).catch(function (err) {
					return reject(err);
				});
			}));
		}
	});

	$scope.promiseList.push(configure());
}]);