'use strict';

angular.module('app').controller('PartnerContactDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$q', 'UriConfig', 'OrmPartnerContactSet', function ($scope, $rootScope, $state, $stateParams, $q, UriConfig, OrmPartnerContactSet) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];
	$scope.contactMap = {};

	var contactTypeList = ['contact_types.full_name', 'contact_types.address', 'contact_types.phone', 'contact_types.email', 'contact_types.other'];

	function configure() {
		return $q(function (resolve, reject) {
			$q.resolve().then(function () {
				if ($stateParams.id) {
					return OrmPartnerContactSet.view({
						id: function () {
							return UriConfig.useSlugifyName ? undefined : $stateParams.id;
						}(),
						filter: {
							where: function () {
								return UriConfig.useSlugifyName ? {
									slugifyName: $stateParams.id
								} : undefined;
							}(),
							include: ['contacts']
						}
					}).$promise;
				} else {
					return $q.resolve();
				}
			}).then(function (result) {
				$scope.contactMap = {};
				if (result) {
					if (result.contacts) {
						result.contacts.forEach(function (element) {
							$scope.contactMap[element.typeId] = element;
						});
					}
				}
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = contactTypeList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var contactType = _step.value;

						if (!$scope.contactMap[contactType]) {
							$scope.contactMap[contactType] = {
								contactSetId: $stateParams.id,
								typeId: contactType,
								view: undefined
							};
						}
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				return resolve() || null;
			}).catch(function (err) {
				if (err.status === 404) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
				} else {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
				}
				return reject(err);
			});
		});
	};

	$scope.process = function () {
		var promise = $q(function (resolve, reject) {
			Promise.resolve().then(function () {
				if (!$scope.contactMap['contact_types.full_name'].view || $scope.contactMap['contact_types.full_name'].view.length === 0) {
					return $q.reject(new Error('Необходимо заполнить поле "Имя"!'));
				}
				if (!$scope.contactMap['contact_types.address'].view || $scope.contactMap['contact_types.address'].view.length === 0) {
					return $q.reject(new Error('Необходимо заполнить поле "Адрес"!'));
				}
				if (!$scope.contactMap['contact_types.phone'].view || $scope.contactMap['contact_types.phone'].view.length === 0) {
					return $q.reject(new Error('Необходимо заполнить поле "Телефон"!'));
				}
				var newContactSet = {
					contactList: []
				};
				if ($stateParams.id) {
					newContactSet.id = $stateParams.id;
				}
				var _iteratorNormalCompletion2 = true;
				var _didIteratorError2 = false;
				var _iteratorError2 = undefined;

				try {
					for (var _iterator2 = contactTypeList[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
						var contactType = _step2.value;

						var contact = $scope.contactMap[contactType];
						var item = {
							typeId: contactType,
							view: contact.view
						};
						if (item.view) {
							newContactSet.contactList.push(item);
						}
					}
				} catch (err) {
					_didIteratorError2 = true;
					_iteratorError2 = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion2 && _iterator2.return) {
							_iterator2.return();
						}
					} finally {
						if (_didIteratorError2) {
							throw _iteratorError2;
						}
					}
				}

				return OrmPartnerContactSet.process(newContactSet).$promise;
			}).then(function () {
				$state.go('user.info');
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.promiseList.push(configure());
}]);