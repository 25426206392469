'use strict';

(function () {
	angular.module('app').directive('nwName', function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs['nwName'];
				// Получить объект товара из переменной scope с указанным в значении атрибута именем.
				var object = scope.$eval(attributeValue);
				var text = object && (object.displayName || object.name);
				if (text) {
					element.text(text);
				} else {
					scope.$watch(attributeValue, function (newValue) {
						var text = newValue && (newValue.displayName || newValue.name);
						if (text) {
							element.text(text);
						}
					});
				}
			}
		};
	});
})();