'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	angular.module('natiwi.navigation-menu').directive('nwNavLink', [function () {
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			require: ['^nwNavMenu'],
			// scope: true,
			bindToController: true,
			template: '<a href data-ng-transclude></a>'
		};
	}]);
})();