'use strict';

angular.module('app').controller('OrderDetailController', ['$rootScope', '$state', '$scope', '$stateParams', '$window', '$q', 'OrmCustomerOrder', 'OrmPaymentOption', 'OrmLayoutTemplate', function ($rootScope, $state, $scope, $stateParams, $window, $q, OrmCustomerOrder, OrmPaymentOption, OrmLayoutTemplate) {
	// Если идентификатор не указан, то возвращаем в список новостей
	if (!$stateParams.id) {
		$scope.promiseList.push($state.go('user.orders.list'));
		return;
	}

	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	$scope.orderItem = {};
	$scope.templateList = undefined;
	$scope.canProcessPayment = false;

	function configure() {
		return $q(function (resolve, reject) {
			OrmCustomerOrder.view({
				id: $stateParams.id,
				filter: {
					include: ['status', 'paymentOption', 'coupon', 'partner', {
						relation: 'acquiringOperations',
						scope: {
							where: {
								statusId: 'acquiring_operation_statuses.paid'
							}
						}
					}, {
						relation: 'contactSet',
						scope: {
							include: 'contacts'
						}
					}, 'productItems']
				}
			}).$promise.then(function (result) {
				if (!result) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
					return resolve() || null;
				}

				$scope.orderItem = result;
				result.discount = 0;
				result.bonusPointOutcome = 0;
				result.bonusPointIncome = 0;
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = result.productItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var productItem = _step.value;

						result.discount += productItem.autoDiscountAmount || 0;
						result.bonusPointOutcome += productItem.bonusPointOutcomeConverted || 0;
						result.bonusPointIncome += productItem.bonusPointIncomeConverted || 0;
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				switch (result.paymentOption.typeId) {
					case 'payment_option_types.cash':
					case 'payment_option_types.cashless':
						$scope.canProcessPayment = false;
						break;
					case 'payment_option_types.bank_card':
						$scope.canProcessPayment = result.acquiringOperations.length === 0;
						break;
				}
				return OrmLayoutTemplate.viewList({
					filter: {
						where: function () {
							var result = {
								targetModel: OrmCustomerOrder.modelName
							};
							if ($scope.orderItem.paymentOption.typeId !== 'payment_option_types.cashless') {
								result.predefinedName = {
									nin: ['NatiwiPaymentOptionKindCashlessInvoicePrintTemplate']
								};
							}
							return result;
						}()
					},
					params: {
						elementType: 0
					},
					// Для совместимости с более старыми версиями бэкэнда
					options: {
						elementType: 0
					}
				}).$promise;
			}).then(function (result) {
				var output = result && result.output;
				if (output) {
					var list = [];

					var _loop = function _loop(item) {
						list.push({
							id: item.id,
							name: item.name + ' (\u041F\u0435\u0447\u0430\u0442\u044C)',
							displayName: function () {
								if (item.displayName) {
									return item.displayName + ' (\u041F\u0435\u0447\u0430\u0442\u044C)';
								}
								return undefined;
							}(),
							format: 'html'
						});
						list.push({
							id: item.id,
							name: item.name + ' (PDF)',
							displayName: function () {
								if (item.displayName) {
									return item.displayName + ' (PDF)';
								}
								return undefined;
							}(),
							format: 'pdf'
						});
					};

					var _iteratorNormalCompletion2 = true;
					var _didIteratorError2 = false;
					var _iteratorError2 = undefined;

					try {
						for (var _iterator2 = output[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
							var item = _step2.value;

							_loop(item);
						}
					} catch (err) {
						_didIteratorError2 = true;
						_iteratorError2 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion2 && _iterator2.return) {
								_iterator2.return();
							}
						} finally {
							if (_didIteratorError2) {
								throw _iteratorError2;
							}
						}
					}

					$scope.templateList = list;
				}
				return resolve() || null;
			}).catch(function (err) {
				if (err.status === 404) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
				} else {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
				}
				return reject(err);
			});
		});
	};

	$scope.processPayment = function () {
		var promise = $q(function (resolve, reject) {
			if ($scope.canProcessPayment) {
				OrmPaymentOption.performOperation({
					documentId: $scope.orderItem.id,
					paymentOptionId: $scope.orderItem.paymentOption.id
				}).$promise.then(function (result) {
					if (result.formUrl) {
						$window.open(result.formUrl, '_self');
					} else {
						$rootScope.$broadcast('notification', {
							type: 'success',
							message: 'Оплата успешно прошла'
						});
						$scope.promiseList.push(configure());
					}
					return resolve() || null;
				}).catch(function (err) {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
					return reject(err);
				});
			} else {
				$rootScope.$broadcast('notification', {
					type: 'warning',
					message: 'Заказ не может быть оплачен'
				});
				return resolve();
			}
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.processTemplate = function (template) {
		var promise = $q(function (resolve, reject) {
			var url = '/api/layoutTemplates/' + template.id + '/display?objectId=' + $scope.orderItem.id + '&params={"format":"' + template.format + '"}';
			var templateWindow = $window.open(url, '_blank');
			if (!templateWindow) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: 'Окно заблокировано, проверьте настройки браузера'
				});
			}
			return resolve();
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.promiseList.push(configure());
}]);