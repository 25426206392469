'use strict';

angular.module('app').factory('ConstantService', ['$rootScope', '$q', '__', 'OrmConstant', function ($rootScope, $q, __, OrmConstant) {
	var constantList = OrmConstant.find();
	constantList.$promise.then(function (result) {
		var _iteratorNormalCompletion = true;
		var _didIteratorError = false;
		var _iteratorError = undefined;

		try {
			for (var _iterator = result[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
				var constant = _step.value;

				if (constant.valueType !== 'relation') {
					constant.value = constant.valueId;
				}
				$rootScope.constants[constant.predefinedName] = constant;
			}
		} catch (err) {
			_didIteratorError = true;
			_iteratorError = err;
		} finally {
			try {
				if (!_iteratorNormalCompletion && _iterator.return) {
					_iterator.return();
				}
			} finally {
				if (_didIteratorError) {
					throw _iteratorError;
				}
			}
		}
	});
	$rootScope.constants = {
		fetchConstant: function fetchConstant(key) {
			return __.get($rootScope.constants, key + '.value', undefined);
		},
		get: function get(key) {
			return __.get($rootScope.constants, key + '.value', undefined);
		},
		fetchConstants: function fetchConstants(keys) {
			return __.chain($rootScope.constants).pick(keys).mapValues('value').value();
		}
	};

	function get(key) {
		return $q(function (resolve, reject) {
			(function () {
				if (constantList.$promise && !constantList.$resolved) {
					return constantList.$promise;
				} else {
					return $q.resolve(constantList);
				}
			})().then(function (result) {
				return resolve($rootScope.constants.get(key));
			}).catch(function (err) {
				return reject(err);
			});
		});
	}

	function fetchConstant(key) {
		return $q(function (resolve, reject) {
			(function () {
				if (constantList.$promise && !constantList.$resolved) {
					return constantList.$promise;
				} else {
					return $q.resolve(constantList);
				}
			})().then(function (result) {
				return resolve($rootScope.constants.fetchConstant(key));
			}).catch(function (err) {
				return reject(err);
			});
		});
	}

	function fetchConstants(keys) {
		return $q(function (resolve, reject) {
			(function () {
				if (constantList.$promise && !constantList.$resolved) {
					return constantList.$promise;
				} else {
					return $q.resolve(constantList);
				}
			})().then(function (result) {
				return resolve($rootScope.constants.fetchConstants(keys));
			}).catch(function (err) {
				return reject(err);
			});
		});
	}
	return {
		get: get,
		fetchConstant: fetchConstant,
		fetchConstants: fetchConstants
	};
}]);