'use strict';

(function () {
	var DIRECTIVE_NAME = 'currentDate';

	angular.module('dateTime').directive(DIRECTIVE_NAME, ['$filter', function ($filter) {
		return {
			restrict: 'A',
			scope: {
				attributeString: '@' + DIRECTIVE_NAME
			},
			link: function link(scope, element, attrs) {
				// Получение строки формата из значения атрибута и объекта текущей даты на стороне клиента (JavaScript). Подразумевается, что посетитель сайта не из будущего или прошлого.
				var dateFormat = scope.attributeString,
				    date = new Date();

				// Если объект товара не найден, значит формат не указан,
				if (!dateFormat) {
					// использовать формат по умолчанию.
					element.text(date);
				} else {
					element.text($filter('date')(date, dateFormat));
				}
			}
		};
	}]);
})();