'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwImage';

	angular.module('app').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				var image = void 0;
				var size = 'large';

				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];
				// Определить, что в значении атрибута - объект с параметрами или строка, описывающая объект товара в scope.
				var isObject = attributeValue.indexOf('{') !== -1;
				if (isObject) {
					var properties = scope.$eval(attributeValue);

					// Если объект успешно расчитан,
					if (properties) {
						// получить из него продукт и индекс необходимого изображения.
						image = properties.image;
						size = properties.size || size;
					} else {
						// Если не удалось распарсить объект из строки, значит в значении атрибута опечатка.
						throw new Error(DIRECTIVE_NAME + ': в значении атрибута опечатка. Не удаётся вычислить значение атрибута как объект. Значение атрибута: ' + attributeValue);
					}
				} else {
					image = scope.$eval(attributeValue);
				}

				// Функция обработки объекта товара для генерации атрибутов тега изображения, на который добавлена данная директива.
				if (image) {
					var styleAttr = element.attr('style') || '';
					if (styleAttr.length > 0) {
						styleAttr += ';';
					}
					styleAttr += 'display: block;';

					var imageUrl = '/api/images/' + image.id + '/' + (size ? size : 'large') + '/download';
					if (isIE()) {
						styleAttr += 'background-image:url(\'' + imageUrl + '\');';
						styleAttr += 'background-position:center center;';
						styleAttr += 'background-repeat:no-repeat;';
						styleAttr += 'background-size:contain;';
						styleAttr += 'width:100%;';
						styleAttr += 'height:100%;';
					} else {
						if (image.preview) {
							styleAttr += 'background:url(data:image/jpeg;base64,' + image.preview + ');';
							styleAttr += 'filter:blur(0px);';
							styleAttr += 'background-size:100%;';
						}
						styleAttr += 'display:block;';
						styleAttr += 'max-width:100%;';
						attrs.$set('src', imageUrl);
					}
					element.attr('style', styleAttr);
					if (image.description) {
						element.attr('alt', image.description);
					}
				}
			}
		};
	});
})();