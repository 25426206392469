'use strict';

angular.module('app').factory('NavigationService', ['$q', '$rootScope', 'OrmProduct', function ($q, $rootScope, OrmProduct) {
	// Локальные переменные.
	var lastParent = void 0;
	var groupsById = {};
	var groupsBySlugifyName = {};
	var groups = void 0;
	var elements = void 0;

	// Внутренние функции.
	function configure() {
		if (!groups) {
			elements = OrmProduct.viewList({
				filter: {
					include: ['primaryImage', 'htmlMetaTags', 'kind'],
					order: ['priority ASC', 'name ASC']
				},
				params: {
					elementType: 1
				},
				// Для совместимости с более старыми версиями бэкэнда
				options: {
					elementType: 1
				}
			});
			elements.$promise.then(function (result) {
				groups = result.output;
				for (var k1 = 0; k1 < groups.length; k1++) {
					var g1 = groups[k1];
					g1.childrens = [];
					groupsById[g1.id] = g1;
					groupsBySlugifyName[g1.slugifyName] = g1;
					for (var k2 = 0; k2 < groups.length; k2++) {
						var g2 = groups[k2];
						if (k1 !== k2) {
							if (g2.parentId === g1.id) {
								g1.childrens.push(g2);
							}
						}
					}
				}
			});
		}
	}

	// Экспортируемые функции.
	var getGroupsByParent = function getGroupsByParent(parent) {
		var getGroupsByParentResult = [];
		getGroupsByParentResult.$promise = $q(function (resolve, reject) {
			var handle = function handle() {
				for (var k1 = 0; k1 < groups.length; k1++) {
					var g1 = groups[k1];
					if (parent !== g1) {
						if (g1.parentId === (parent ? parent.id : null)) {
							getGroupsByParentResult.push(g1);
						}
					}
				}

				getGroupsByParentResult.$resolved = true;
				resolve(getGroupsByParentResult);
			};
			if (elements.$resolved) {
				handle();
			} else {
				elements.$promise.then(function () {
					handle();
				});
			}
		});
		getGroupsByParentResult.$resolved = false;
		return getGroupsByParentResult;
	};

	var rootElement = {
		id: null,
		name: 'Каталог',
		elementType: 1
	};

	var getRootGroups = function getRootGroups() {
		var getRootGroupsResult = [];
		getRootGroupsResult.$promise = $q(function (resolve, reject) {
			getGroupsByParent(rootElement).$promise.then(function (result) {
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = result[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var item = _step.value;

						getRootGroupsResult.push(item);
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				resolve(getRootGroupsResult);
				getRootGroupsResult.$resolved = true;
			});
		});
		getRootGroupsResult.$resolved = false;

		return getRootGroupsResult;
	};

	var getHistoryByParent = function getHistoryByParent(parent) {
		var getHistoryByParentResult = [rootElement];
		getHistoryByParentResult.$promise = $q(function (resolve, reject) {
			var handle = function handle() {
				if (parent) {
					var toPush = [];

					var p = parent.id;
					while (p) {
						var group = groupsById[p];
						if (group) {
							toPush.push(group);
							p = group.parentId;
						} else {
							p = null;
						}
					}

					toPush = toPush.reverse();
					for (var i = 0; i < toPush.length; i++) {
						getHistoryByParentResult.push(toPush[i]);
					}
					resolve(getHistoryByParentResult);
					getHistoryByParentResult.$resolved = true;
				}
			};
			if (elements.$resolved) {
				handle();
			} else {
				elements.$promise.then(function () {
					handle();
				});
			}
		});
		getHistoryByParentResult.$resolved = false;
		if (!parent) {
			return getHistoryByParentResult;
		}
		return getHistoryByParentResult;
	};

	var setLastParent = function setLastParent(parent) {
		if (!parent) {
			parent = rootElement;
		}
		if (parent !== lastParent) {
			lastParent = parent;
			$rootScope.$broadcast('navigationGroupSelected', lastParent);
		}
	};

	var getLastParent = function getLastParent() {
		return lastParent;
	};

	var getGroupsCount = function getGroupsCount() {
		return $q(function (resolve, reject) {
			elements.$promise.then(function (result) {
				resolve(result.length);
			}).catch(function (error) {
				reject(error);
			});
		});
	};

	var getById = function getById(id) {
		if (!id) return {
			$promise: $q.resolve(null)
		};
		var value = {};
		value.$promise = $q(function (resolve, reject) {
			var handle = function handle() {
				var obj = groupsById[id];
				for (var k in obj) {
					value[k] = obj[k];
				}value.$resolved = true;
				return resolve(value);
			};
			if (elements.$resolved) {
				handle();
			} else {
				elements.$promise.then(function () {
					handle();
				});
			}
		});
		value.$resolved = false;
		return value;
	};

	var getBySlugifyName = function getBySlugifyName(slugifyName) {
		if (!slugifyName) return {
			$promise: $q.resolve(null)
		};
		var value = {};
		value.$promise = $q(function (resolve, reject) {
			var handle = function handle() {
				var obj = groupsBySlugifyName[slugifyName];
				for (var k in obj) {
					value[k] = obj[k];
				}value.$resolved = true;
				return resolve(value);
			};
			if (elements.$resolved) {
				handle();
			} else {
				elements.$promise.then(function () {
					handle();
				});
			}
		});
		value.$resolved = false;
		return value;
	};

	var getChilds = function getChilds(parent, recursively) {
		var getChildsResult = [];
		getChildsResult.$promise = $q(function (resolve, reject) {
			var handle = function handle(parent, recursively) {
				var parentId = parent && parent.id || null;
				var _iteratorNormalCompletion2 = true;
				var _didIteratorError2 = false;
				var _iteratorError2 = undefined;

				try {
					for (var _iterator2 = groups[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
						var group = _step2.value;

						if (group.parentId === parentId) {
							getChildsResult.push(group);
							if (recursively) {
								handle(group, recursively);
							}
						}
					}
				} catch (err) {
					_didIteratorError2 = true;
					_iteratorError2 = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion2 && _iterator2.return) {
							_iterator2.return();
						}
					} finally {
						if (_didIteratorError2) {
							throw _iteratorError2;
						}
					}
				}

				getChildsResult.$resolved = true;
				resolve(getChildsResult);
			};
			if (elements.$resolved) {
				handle(parent, recursively);
			} else {
				elements.$promise.then(function () {
					handle(parent, recursively);
				});
			}
		});
		getChildsResult.$resolved = false;
		return getChildsResult;
	};

	var getAll = function getAll() {
		var result = [];
		result.$promise = $q(function (resolve, reject) {
			var handle = function handle() {
				for (var i = 0; i < groups.length; i++) {
					result.push(groups[i]);
				}
				resolve(result);
				result.$resolved = true;
			};
			if (elements.$resolved) {
				handle();
			} else {
				elements.$promise.then(function () {
					handle();
				});
			}
		});
		result.$resolved = false;
		return result;
	};

	// Выполнить инициализацию сервиса.
	configure();

	return {
		getHistoryByParent: getHistoryByParent,
		getGroupsByParent: getGroupsByParent,
		getRootGroups: getRootGroups,
		getById: getById,
		getBySlugifyName: getBySlugifyName,
		getLastParent: getLastParent,
		setLastParent: setLastParent,
		getGroupsCount: getGroupsCount,
		getChilds: getChilds,
		getAll: getAll
	};
}]);