'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwDate';

	angular.module('app').directive(DIRECTIVE_NAME, function ($filter) {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];

				scope.$watch(attributeValue, function (newValue) {
					if (newValue && angular.isString(newValue)) {
						// Обычное отображение даты в указанном формате.
						var dateFormat = 'yyyy.MM.dd HH:mm:ss';

						element.text($filter('date')(newValue, dateFormat));
					}
				});
			}
		};
	});
})();