'use strict';

(function ($) {
	var DIRECTIVE_NAME = 'scrollTo';

	angular.module('scrollTo').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			scope: {
				scrollToSelector: '@' + DIRECTIVE_NAME,
				scrollToSpeed: '@' + DIRECTIVE_NAME + 'Speed'
			},
			link: function link(scope, element, attr) {
				// Инициализация значений по умолчанию.
				var scrollToSelectorChosen = scope.scrollToSelector,
				    scrollToSpeedChosen = 'slow',
				    scrollToSpeedAsNumber = +scope.scrollToSpeed;
				if (scope.scrollToSpeed === 'slow' || scope.scrollToSpeed === 'fast') {
					scrollToSpeedChosen = scope.scrollToSpeed;
				} else if (angular.isNumber(scrollToSpeedAsNumber) && scrollToSpeedAsNumber >= 0) {
					scrollToSpeedChosen = scrollToSpeedAsNumber;
				}

				// Обработчик клика по элементу с данной директивой.
				element.on('click', function () {
					var offsetTopTarget = void 0;

					if (angular.isString(scrollToSelectorChosen) && scrollToSelectorChosen !== '') {
						var jElement = $(scrollToSelectorChosen);
						if (jElement) {
							var offset = jElement.offset();
							if (offset) {
								offsetTopTarget = offset.top;
							}
						}
					} else {
						offsetTopTarget = 0;
					}

					$('html,body').animate({
						scrollTop: offsetTopTarget
					}, scrollToSpeedChosen);
				});
			}
		};
	});
})(jQuery);