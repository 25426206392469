'use strict';

angular.module('app').controller('NewsDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$sce', '$q', '$http', '$interpolate', 'UriConfig', 'OrmNewsMetadata', 'ngMeta', 'ngMetaTagTemplates', function ($scope, $rootScope, $state, $stateParams, $sce, $q, $http, $interpolate, UriConfig, OrmNewsMetadata, ngMeta, ngMetaTagTemplates) {
	// Если идентификатор не указан, то возвращаем в список новостей
	if (!$stateParams.id) {
		$scope.promiseList.push($state.go('news.list'));
		return;
	}

	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	// Переменные в scope
	$scope.newsItem = {};
	$scope.newsItemHtml = undefined;

	function configure() {
		return $q(function (resolve, reject) {
			OrmNewsMetadata.view({
				id: function () {
					return UriConfig.useSlugifyName ? undefined : $stateParams.id;
				}(),
				filter: {
					where: function () {
						return UriConfig.useSlugifyName ? {
							slugifyName: $stateParams.id
						} : undefined;
					}(),
					include: ['author', 'container', 'htmlMetaTags']
				}
			}).$promise.then(function (result) {
				var newsItem = result;
				if (!newsItem) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
					return resolve() || null;
				}
				var container = newsItem.container;
				if (!container) {
					throw new Error('\u041A\u043E\u043D\u0442\u0435\u0439\u043D\u0435\u0440 \u0441 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440\u043E\u043C ' + container.id + ' \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D');
				}

				// Устанавливаем мета теги в соответствии с настройками
				var htmlMetaTagTitleExist = false;
				var htmlMetaTagDescriptionExist = false;

				var htmlMetaTagList = newsItem && newsItem.htmlMetaTags || [];
				if (htmlMetaTagList && htmlMetaTagList.length > 0) {
					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = htmlMetaTagList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var htmlMetaTag = _step.value;

							if (htmlMetaTag.name === 'title') {
								ngMeta.setTitle(htmlMetaTag.content);
								ngMeta.setTag('og:title', htmlMetaTag.content);
								htmlMetaTagTitleExist = true;
							} else if (htmlMetaTag.name === 'description') {
								ngMeta.setTag('description', htmlMetaTag.content);
								ngMeta.setTag('og:description', htmlMetaTag.content);
								htmlMetaTagDescriptionExist = true;
							} else {
								ngMeta.setTag(htmlMetaTag.name, htmlMetaTag.content);
							}
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}
				}

				if (newsItem && !htmlMetaTagTitleExist) {
					var titleTag = $interpolate(ngMetaTagTemplates.newsTitleTemplate)({
						news: newsItem
					});
					ngMeta.setTitle(titleTag);
					ngMeta.setTag('og:title', titleTag);
				}
				if (newsItem && !htmlMetaTagDescriptionExist) {
					var descriptionTag = $interpolate(ngMetaTagTemplates.newsDescriptionTemplate)({
						news: newsItem
					});
					ngMeta.setTag('description', descriptionTag);
					ngMeta.setTag('og:description', descriptionTag);
				}

				// Устанавливаем мета-информацию новости
				$scope.newsItem = newsItem;

				return $http({
					method: 'GET',
					url: '/api/containers/' + container.name + '/download/' + result.filename
				});
			}).then(function (result) {
				$scope.newsItemHtml = $sce.trustAsHtml(result.data);
				return resolve() || null;
			}).catch(function (err) {
				if (err.status === 404) {
					$scope.promiseList.push($state.go('404', {
						errorUrl: window.location
					}));
				} else {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
				}
				return reject(err);
			});
		});
	};

	$scope.promiseList.push(configure());
}]);