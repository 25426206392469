'use strict';

angular.module('app').controller('Error404Controller', ['$scope', '$timeout', '$location', 'ngMeta', function ($scope, $timeout, $location, ngMeta) {
	$timeout(function () {
		ngMeta.setTitle('404 не найдено');
		ngMeta.setTag('og:title', '404 не найдено');
		ngMeta.setTag('description', 'К сожалению, запрашиваемая страница не найдена');
		ngMeta.setTag('og:description', 'К сожалению, запрашиваемая страница не найдена');
	});

	$scope.siteDomain = $location.host();
	$scope.siteUrl = $location.protocol() + '://' + $location.host() + function () {
		var port = $location.port();
		if (port === 80 || port === 443) {
			return '';
		} else {
			return ':' + port;
		}
	}();
}]);