'use strict';

// Provides directive that searchs for the elements with the data-map-viewport
// and data-map-marker attributes in the element that has data-simple-map attribute,
// then creates map of the specified type in the viewport element and
// adds markers for each marker elements.
// Directive removes elements with "data-map-marker" attributes after processing.
// Currently only Yandex and Google maps are supported.
// External dependencies:
//      http://maps.google.com/maps/api/js if Google map required
//      https://api-maps.yandex.ru/2.1/?lang=en_US if Yandex map required
// Usage example:
/*
<section data-simple-map>
    <div data-map-viewport="{ type: 'yandex', latitude: 44.707744, longitude: 37.787857, zoom: 16 }"></div>
    <div data-map-marker="{ latitude: 44.705879, longitude: 37.78434, draggable: true, openBalloon: true }">
        <h3>Marker One</h3>
        <address>
            <p>Musicians</p>
            <p>Arbat st.</p>
            <p>Moscow</p>
        </address>
    </div>
    <div data-map-marker data-map-marker="{ openBalloon: true }">
        <h3>Marker Two</h3>
        <address>
            <p>Donuts</p>
            <p>Povarskaya st.</p>
            <p>Moscow</p>
        </address>
    </div>
</section>
*/
// NOTE: Yandex map can't open several balloons simultaneously by design. Therefore if several markers have openBalloon: true property value, only last processed marker balloon will be shown.

(function () {
    angular.module("simpleMap", []);
})();