'use strict';

angular.module('app').controller('ProductGroupListController', ['$q', '$rootScope', '$scope', '$stateParams', 'NavigationService', function ($q, $rootScope, $scope, $stateParams, NavigationService) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	// Переменные в scope
	$scope.groupList = [];
	$scope.currentGroup = undefined;

	// Internal functions.
	var configure = function configure() {
		return $q(function (resolve, reject) {
			NavigationService.getGroupsByParent(null).$promise.then(function (result) {
				$scope.groupList = result;
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
	};

	// Параметр текущей группы
	$scope.currentGroup = function () {
		if ($stateParams.group) {
			return NavigationService.getById($stateParams.group);
		} else {
			return undefined;
		}
	}();

	$scope.promiseList.push(configure());
}]);