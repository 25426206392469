'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	angular.module('natiwi.navigation-menu').directive('nwNavCategory', [function () {
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			require: ['^nwNavMenu'],
			// scope: true,
			bindToController: true,
			template: '<ul class="is-hidden" ng-transclude></ul>'
		};
	}]);
})();