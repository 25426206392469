'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwProductListViewMode';

	angular.module('app').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];

				// Получить строку из переменной scope с указанным в значении атрибута именем.
				var viewMode = scope.$eval(attributeValue);

				switch (viewMode) {
					case 'grid':
					default:
						scope.templateUrl = 'core/store/products/list/products.list-grid-view.template.html';

						break;

					case 'list':
						scope.templateUrl = 'core/store/products/list/products.list-list-view.template.html';

						break;
				}
			},
			template: '<div data-ng-include="templateUrl"></div>'
		};
	});
})();