'use strict';

angular.module('app').controller('ProcessOrderController', ['$q', '$scope', '$rootScope', '$state', '$stateParams', '$window', 'ConstantService', 'ShoppingCartService', 'UserService', 'OrmCustomerOrder', 'OrmPaymentOption', 'OrmPartnerContactSet', function ($q, $scope, $rootScope, $state, $stateParams, $window, ConstantService, ShoppingCartService, UserService, OrmCustomerOrder, OrmPaymentOption, OrmPartnerContactSet) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];
	$scope.messageList = [];
	$scope.paymentTypeOptions = {};
	$scope.bonusPointOutcome = 0;
	$scope.coupon = {
		code: ''
	};
	$scope.note = undefined;
	$scope.shoppingCart = ShoppingCartService.getCart();

	var contactTypeList = ['contact_types.full_name', 'contact_types.address', 'contact_types.phone', 'contact_types.email', 'contact_types.other'];
	var contactSetList = [];
	var skipWatchPaymentTypeOptionsValue = false;

	function applyLoyaltyPolicy(fetchBonusPointBalance) {
		var promise = $q(function (resolve, reject) {
			if ($scope.shoppingCart.items.length === 0) {
				return resolve();
			}
			ConstantService.get('AllowLoyaltyPolicy').then(function (result) {
				var AllowLoyaltyPolicy = result;
				if (AllowLoyaltyPolicy) {
					return ShoppingCartService.applyLoyaltyPolicy({
						paymentOption: $scope.paymentTypeOptions.value,
						fetchBonusPointBalance: fetchBonusPointBalance,
						couponCode: function () {
							var couponCode = $scope.coupon && $scope.coupon.code;
							if (couponCode && couponCode.length > 0) {
								return couponCode;
							}
							return undefined;
						}()
					});
				}
				return $q.resolve();
			}).then(function (result) {
				if (result) {
					$scope.messageList = result.messageList;
				}
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	}

	function configure() {
		return $q(function (resolve, reject) {
			// Получаем купон из URL
			$scope.coupon.code = $stateParams.coupon || '';
			// Получаем доступные способы оплаты
			OrmPaymentOption.find().$promise.then(function (result) {
				skipWatchPaymentTypeOptionsValue = true;
				if (result.length > 0) {
					$scope.paymentType = result[0];
					$scope.paymentTypeOptions = {
						value: $scope.paymentType,
						values: result
					};
				} else {
					$scope.paymentType = {
						name: 'Невозможно сейчас оплатить',
						value: 'null'
					};
				}
				return $q.resolve();
			}).then(function (result) {
				// Расчитываем доступный лимит баллов, которые можем потратить
				// Получаем баланс бонусных баллов
				return applyLoyaltyPolicy(true);
			}).then(function () {
				//получаем набор контактной информации и контакты
				if ($rootScope.currentUser) {
					return OrmPartnerContactSet.find({
						filter: {
							include: 'contacts'
						}
					}).$promise;
				} else {
					return $q.resolve([]);
				}
			}).then(function (result) {
				contactSetList = result;
				var newContactSetList = [];
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = result[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var item = _step.value;

						var contactSet = {
							id: item.id,
							name: item.name,
							contacts: []
						};
						var _iteratorNormalCompletion3 = true;
						var _didIteratorError3 = false;
						var _iteratorError3 = undefined;

						try {
							for (var _iterator3 = item.contacts[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
								var contact = _step3.value;

								var newContact = {
									typeId: contact.typeId,
									view: contact.view,
									contactSetId: contact.contactSetId
								};
								contactSet.contacts.push(newContact);
							}
						} catch (err) {
							_didIteratorError3 = true;
							_iteratorError3 = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion3 && _iterator3.return) {
									_iterator3.return();
								}
							} finally {
								if (_didIteratorError3) {
									throw _iteratorError3;
								}
							}
						}

						newContactSetList.push(contactSet);
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				$scope.contactSet = {
					name: 'Новый набор контактов',
					contacts: []
				};
				if ($rootScope.currentUser) {
					var principal = $rootScope.currentUser.principal;
					if (principal) {
						$scope.contactSet.contacts.push({
							typeId: 'contact_types.full_name',
							view: [principal.firstName || undefined, principal.lastName || undefined, principal.middleName || undefined].join(' ')
						});
					}
					var email = $rootScope.currentUser.email;
					if (email) {
						$scope.contactSet.contacts.push({
							typeId: 'contact_types.email',
							view: email
						});
					}
				}
				newContactSetList.push($scope.contactSet);
				$scope.contactSet = newContactSetList[0];
				$scope.contactSetsOptions = {
					value: $scope.contactSet,
					values: newContactSetList
				};
				$scope.$watch('contactSetsOptions.value', function (result) {
					$scope.contactMap = {};
					if (result) {
						if (result.contacts) {
							result.contacts.forEach(function (element) {
								$scope.contactMap[element.typeId] = element;
							});
						}

						var _loop = function _loop(contactType) {
							if (!$scope.contactMap[contactType]) {
								$scope.contactMap[contactType] = {
									contactSetId: result.id,
									typeId: contactType,
									view: function () {
										if (contactType === 'contact_types.full_name') {
											return $rootScope.currentUser && $rootScope.currentUser.username;
										}
										if (contactType === 'contact_types.phone') {
											return '7';
										}
										return undefined;
									}()
								};
							}
						};

						var _iteratorNormalCompletion2 = true;
						var _didIteratorError2 = false;
						var _iteratorError2 = undefined;

						try {
							for (var _iterator2 = contactTypeList[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
								var contactType = _step2.value;

								_loop(contactType);
							}
						} catch (err) {
							_didIteratorError2 = true;
							_iteratorError2 = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion2 && _iterator2.return) {
									_iterator2.return();
								}
							} finally {
								if (_didIteratorError2) {
									throw _iteratorError2;
								}
							}
						}
					}
				});
				return resolve();
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
	};

	$scope.process = function () {
		var promise = $q(function (resolve, reject) {
			var oldContactSet = contactSetList.filter(function (value) {
				if (value.id === $scope.contactSetsOptions.value.id) {
					return value;
				}
			})[0];
			var contactSetDidChange = false;
			var contactSetWillSave = false;
			var AllowAnonymousUser = false;
			(function () {
				if (!$scope.contactMap['contact_types.full_name'].view || $scope.contactMap['contact_types.full_name'].view.length === 0) {
					if (!$rootScope.currentUser || $rootScope.currentUser && $rootScope.currentUser.anonymous) {
						return $q.reject(new Error('Необходимо заполнить поле "Имя"!'));
					}
				}
				if (!$scope.contactMap['contact_types.address'].view || $scope.contactMap['contact_types.address'].view.length === 0) {
					return $q.reject(new Error('Необходимо заполнить поле "Адрес"!'));
				}
				if (!$scope.contactMap['contact_types.phone'].view || $scope.contactMap['contact_types.phone'].view.length < 11) {
					return $q.reject(new Error('Необходимо заполнить поле "Телефон"!'));
				}
				if (!$scope.paymentTypeOptions.value) {
					return $q.reject(new Error('Не выбран способ оплаты'));
				}
				return ConstantService.get('AllowAnonymousUser');
			})().then(function (result) {
				AllowAnonymousUser = result;
				if (AllowAnonymousUser) {
					if (!$rootScope.currentUser) {
						var fullName = $scope.contactMap['contact_types.full_name'].view || '';
						var namePieces = fullName.split(' ');
						var firstName = undefined;
						if (namePieces.length > 0) {
							firstName = namePieces[0];
						}
						var lastName = undefined;
						if (namePieces.length > 1) {
							lastName = namePieces[1];
						}
						return UserService.register(null, null, firstName, lastName, 'partner_types.individual', true);
					}
				} else {
					if (!$rootScope.currentUser) {
						$state.go('user.login');
						return $q.reject(new Error('Необходимо авторизоваться'));
					}
				}
				return $q.resolve();
			}).then(function () {
				var newContactSet = {
					contactList: []
				};

				var _loop2 = function _loop2(contactType) {
					var contact = $scope.contactMap[contactType];
					if (oldContactSet && oldContactSet.contacts) {
						var oldContact = oldContactSet.contacts.filter(function (value) {
							if (contact.typeId === value.typeId) {
								return value;
							}
						})[0] || {};
						if (contact.view !== oldContact.view) {
							contactSetDidChange = true;
						}
					}
					var item = {
						typeId: contactType,
						view: contact.view
					};
					if (item.view) {
						newContactSet.contactList.push(item);
					}
				};

				var _iteratorNormalCompletion4 = true;
				var _didIteratorError4 = false;
				var _iteratorError4 = undefined;

				try {
					for (var _iterator4 = contactTypeList[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
						var contactType = _step4.value;

						_loop2(contactType);
					}
				} catch (err) {
					_didIteratorError4 = true;
					_iteratorError4 = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion4 && _iterator4.return) {
							_iterator4.return();
						}
					} finally {
						if (_didIteratorError4) {
							throw _iteratorError4;
						}
					}
				}

				if (contactSetDidChange) {
					contactSetWillSave = confirm('Контактная информация для набора "' + oldContactSet.name + '" изменилась. Сохранить эти изменения в набор?');
					contactSetWillSave ? newContactSet.id = $scope.contactSetsOptions.value.id : false;
				}
				if (!$scope.contactSetsOptions.value.id || contactSetWillSave) {
					return OrmPartnerContactSet.process(newContactSet).$promise;
				} else {
					return $q.resolve(newContactSet);
				}
			}).then(function (result) {
				var contactSet = {};
				if (result.contacts && result.contacts.length > 0) {
					contactSet.contactList = result.contacts;
				} else if (result.contactList && result.contactList.length > 0) {
					contactSet.contactList = result.contactList;
				} else {
					return $q.reject(new Error('Ошибка сохранения заказа, попробуйте снова!'));
				}
				return $q.resolve(contactSet);
			}).then(function (result) {
				return OrmCustomerOrder.process({
					contactSet: result,
					note: $scope.note,
					paymentOptionId: $scope.paymentTypeOptions.value.id,
					productItemList: function () {
						var result = [];
						var _iteratorNormalCompletion5 = true;
						var _didIteratorError5 = false;
						var _iteratorError5 = undefined;

						try {
							for (var _iterator5 = $scope.shoppingCart.items[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
								var _item = _step5.value;

								result.push({
									productId: _item.product.id,
									featureId: _item.feature && _item.feature.id,
									unitId: _item.product.unitId,
									organizationId: _item.product.organizationId,
									quantity: _item.quantity
								});
							}
						} catch (err) {
							_didIteratorError5 = true;
							_iteratorError5 = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion5 && _iterator5.return) {
									_iterator5.return();
								}
							} finally {
								if (_didIteratorError5) {
									throw _iteratorError5;
								}
							}
						}

						return result;
					}(),
					bonusPointOutcome: $scope.bonusPointOutcome,
					couponCode: function () {
						var couponCode = $scope.coupon && $scope.coupon.code;
						if (couponCode && couponCode.length > 0) {
							return couponCode;
						}
						return undefined;
					}()
				}).$promise;
			}).then(function (result) {
				return OrmPaymentOption.performOperation({
					documentId: result.id,
					paymentOptionId: $scope.paymentTypeOptions.value.id
				}).$promise;
			}).then(function (result) {
				if (result && result.formUrl) {
					$window.open(result.formUrl, '_self');
				} else {
					$rootScope.$broadcast('notification', {
						type: 'success',
						message: 'Ваш заказ принят!'
					});
					$state.go('process.order.success');
				}
				ShoppingCartService.removeAll();
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.increaseElementQuantity = function (r) {
		ShoppingCartService.updateElement(r, {
			quantityOffset: 1,
			notify: false
		});
		applyLoyaltyPolicy(false);
	};

	$scope.decreaseElementQuantity = function (r) {
		ShoppingCartService.updateElement(r, {
			quantityOffset: r.quantity - 1 > 0 ? -1 : 0,
			notify: false
		});
		applyLoyaltyPolicy(false);
	};

	$scope.changeElementQuantity = function (r, quantity) {
		ShoppingCartService.updateElement(r, {
			quantity: quantity > 0 ? +quantity : 1,
			notify: false
		});
		applyLoyaltyPolicy(false);
	};

	$scope.removeElement = function (r) {
		ShoppingCartService.removeElement(r);
		applyLoyaltyPolicy(false);
	};

	$scope.removeAll = function () {
		ShoppingCartService.removeAll();
	};

	$scope.applyCoupon = function () {
		if (!$scope.coupon.code || $scope.coupon.code.length === 0) {
			return $rootScope.$broadcast('notification', {
				type: 'warning',
				message: 'Введите купон'
			});
		}
		applyLoyaltyPolicy(false);
	};

	$scope.bonusPointIncome = function () {
		var loyaltyProgram = $scope.shoppingCart;
		var bonusPointIncome = $scope.shoppingCart.bonusPointIncome;
		if ($scope.bonusPointOutcome > 0 && loyaltyProgram && !loyaltyProgram.earnPointsWhenYouPayWithBonuses) {
			bonusPointIncome = 0;
		}
		return bonusPointIncome;
	};

	//наблюдатели
	$scope.$watch('bonusPointOutcome', function (newValue) {
		$scope.bonusPointOutcome = Math.max(Math.min($scope.bonusPointOutcome, $scope.shoppingCart.bonusPointOutcomeLimit), 0);
	});

	$scope.$watch('shoppingCart.bonusPointOutcomeLimit', function (newValue) {
		$scope.bonusPointOutcome = Math.max(Math.min($scope.bonusPointOutcome, $scope.shoppingCart.bonusPointOutcomeLimit), 0);
	});

	$scope.$watch('paymentTypeOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			if (skipWatchPaymentTypeOptionsValue) {
				skipWatchPaymentTypeOptionsValue = false;
				return;
			}
			applyLoyaltyPolicy(false);
		}
	});

	$scope.promiseList.push(configure());
}]);