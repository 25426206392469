'use strict';

angular.module('app').value('ProductListControllerConfig', {
	fetchRecursiveGroup: false,
	singlePage: false,
	limitList: [{
		name: '15',
		value: 15
	}, {
		name: '25',
		value: 25
	}, {
		name: '50',
		value: 50
	}, {
		name: '75',
		value: 75
	}, {
		name: '100',
		value: 100
	}],
	sortList: [{
		name: 'По умолчанию',
		value: 'priority ASC'
	}, {
		name: 'Название по возрастанию',
		value: 'name ASC'
	}, {
		name: 'Название по убыванию',
		value: 'name DESC'
	}, {
		name: 'Цена по возрастанию',
		value: 'price ASC'
	}, {
		name: 'Цена по убыванию',
		value: 'price DESC'
	}, {
		name: 'Сначала новые',
		value: 'createdAt DESC'
	}, {
		name: 'Сначала старые',
		value: 'createdAt ASC'
	}]
}).provider('ProductListController.Config', function () {
	var options = {};
	this.fetchRecursiveGroup = function (fetchRecursiveGroup) {
		return options.fetchRecursiveGroup = fetchRecursiveGroup;
	};
	this.singlePage = function (singlePage) {
		return options.singlePage = singlePage;
	};
	this.limitList = function (limitList) {
		return options.limitList = limitList;
	};
	this.sortList = function (sortList) {
		return options.sortList = sortList;
	};
	this.$get = ['ProductListControllerConfig', function (ProductListControllerConfig) {
		var tempOptions = ProductListControllerConfig;
		for (var prop in options) {
			if (angular.isObject(options[prop]) && !angular.isArray(options[prop])) {
				angular.extend(tempOptions[prop], options[prop]);
			} else {
				tempOptions[prop] = options[prop];
			}
		}

		return tempOptions;
	}];
}).controller('ProductListController', ['__', '$q', '$rootScope', '$scope', '$state', '$stateParams', '$interpolate', 'ProductListController.Config', 'UriConfig', 'OrmProduct', 'ShoppingCartService', 'NavigationService', 'ngMeta', 'ngMetaTagTemplates', function (__, $q, $rootScope, $scope, $state, $stateParams, $interpolate, ProductListControllerConfig, UriConfig, OrmProduct, ShoppingCartService, NavigationService, ngMeta, ngMetaTagTemplates) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	// Переменные в scope
	$scope.productList = undefined;
	$scope.groupList = undefined;
	$scope.specificationPropertyList = [];
	$scope.specificationValueItemList = [];
	$scope.context = {};
	$scope.context.productListTotalCount = 0;

	// Internal functions.
	var configure = function configure() {
		var group = undefined;
		return $q(function (resolve, reject) {
			(function () {
				if ($scope.group) {
					return $q.resolve($scope.group);
				} else {
					var _group = NavigationService.getById(null);
					if (_group.$resolve) {
						return $q.resolve(_group.value);
					} else {
						return _group.$promise;
					}
				}
			})().then(function (result) {
				if (result && result.slugifyName) {
					return NavigationService.getBySlugifyName(result.slugifyName).$promise;
				}

				return NavigationService.getById(result && result.id || null).$promise;
			}).then(function (result) {
				group = result;

				// Устанавливаем мета теги в соответствии с настройками
				var htmlMetaTagTitleExist = false;
				var htmlMetaTagDescriptionExist = false;

				var htmlMetaTagList = group && group.htmlMetaTags || [];
				if (htmlMetaTagList && htmlMetaTagList.length > 0) {
					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = htmlMetaTagList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var htmlMetaTag = _step.value;

							if (htmlMetaTag.name === 'title') {
								ngMeta.setTitle(htmlMetaTag.content);
								ngMeta.setTag('og:title', htmlMetaTag.content);
								htmlMetaTagTitleExist = true;
							} else if (htmlMetaTag.name === 'description') {
								ngMeta.setTag('description', htmlMetaTag.content);
								ngMeta.setTag('og:description', htmlMetaTag.content);
								htmlMetaTagDescriptionExist = true;
							} else {
								ngMeta.setTag(htmlMetaTag.name, htmlMetaTag.content);
							}
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}
				}

				if (group && !htmlMetaTagTitleExist) {
					var titleTag = $interpolate(ngMetaTagTemplates.catalogTitleTemplate)({
						catalog: group
					});
					ngMeta.setTitle(titleTag);
					ngMeta.setTag('og:title', titleTag);
				}
				if (group && !htmlMetaTagDescriptionExist) {
					var descriptionTag = $interpolate(ngMetaTagTemplates.catalogDescriptionTemplate)({
						catalog: group
					});
					ngMeta.setTag('description', descriptionTag);
					ngMeta.setTag('og:description', descriptionTag);
				}

				// Сообщить сервису навигации о переходе в группу. Это необходимо,
				//чтобы при прямом переходе в веб-приложение по ссылке с указанной группой происходило испускание
				//события обновления текущей группы в сервисе навигации. Иначе оно происходить не будет.
				NavigationService.setLastParent(group);
				$scope.navigation = NavigationService.getHistoryByParent(group);

				return OrmProduct.viewList({
					filter: {
						where: function () {
							if ($scope.searchText) {
								return {};
							} else {
								return group && {
									parentId: function () {
										var result = [group.id];
										var childs = NavigationService.getChilds(group, ProductListControllerConfig.fetchRecursiveGroup);
										if (childs && childs.length > 0) {
											var _iteratorNormalCompletion2 = true;
											var _didIteratorError2 = false;
											var _iteratorError2 = undefined;

											try {
												for (var _iterator2 = childs[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
													var child = _step2.value;

													result.push(child.id);
												}
											} catch (err) {
												_didIteratorError2 = true;
												_iteratorError2 = err;
											} finally {
												try {
													if (!_iteratorNormalCompletion2 && _iterator2.return) {
														_iterator2.return();
													}
												} finally {
													if (_didIteratorError2) {
														throw _iteratorError2;
													}
												}
											}
										}
										if (result.length > 1) {
											return {
												inq: result
											};
										} else {
											return group.id;
										}
									}()
								} || undefined;
							}
						}(),
						limit: function () {
							return $scope.limitOptions.value && $scope.limitOptions.value.value;
						}(),
						skip: function () {
							var limitValue = $scope.limitOptions.value && $scope.limitOptions.value.value || 0;
							var pageValue = $scope.pageOptions.value || 0;
							return (pageValue - 1) * limitValue;
						}(),
						order: function () {
							return $scope.sortOptions.value && $scope.sortOptions.value.value;
						}(),
						include: ['primaryImage']
					},
					params: {
						elementType: 0,
						search: function () {
							return $scope.searchText || null;
						}(),
						specificationSetList: function () {
							var specificationSetId = __.get(group, 'kind.specificationSetId');
							if (__.isNil(specificationSetId)) {
								return undefined;
							} else {
								return [specificationSetId];
							}
						}(),
						specificationFilter: {
							specificationValueItemList: $scope.specificationValueItemList
						}
					},
					// Для совместимости с более старыми версиями бэкэнда
					options: {
						elementType: 0
					}
				}).$promise;
			}).then(function (result) {
				var output = result && result.output || [];
				var outputInfo = result && result.outputInfo || {
					totalCount: output.length
				};

				$scope.productList = output;
				$scope.context.productListTotalCount = outputInfo.totalCount || 0;
				$scope.specificationPropertyList = outputInfo.specificationPropertyList || [];

				var limit = $scope.limitOptions.value && $scope.limitOptions.value.value || 0;

				var pageOptions = [];
				var pageCount = limit === 0 ? 1 : Math.max(Math.ceil($scope.context.productListTotalCount / limit), 1);
				for (var i = 1; i <= pageCount; ++i) {
					pageOptions.push(i);
				}
				$scope.pageOptions.values = pageOptions;
				if ($scope.pageOptions.value > pageCount) {
					$scope.pageOptions.value = pageCount;
				}

				if (ProductListControllerConfig.singlePage) {
					$scope.groupList = NavigationService.getAll();
				} else {
					$scope.groupList = NavigationService.getGroupsByParent(group);
				}
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
	};

	// Параметр поиска номенклатуры
	$scope.searchText = $stateParams.q;
	// Параметр текущей группы
	$scope.group = $stateParams.group ? function () {
		var result = {};

		if (UriConfig.useSlugifyName) {
			result.slugifyName = $stateParams.group;
		} else {
			result.id = $stateParams.group;
		}

		return result;
	}() : null;
	// Режим отображения.
	$scope.view = $stateParams.view;

	// Страница.
	var page = $stateParams.page || 1;
	page = Math.max(parseInt(page), 1);
	if (isNaN(page)) {
		page = 1;
	}

	$scope.pageOptions = {
		value: page,
		values: [1]
	};
	// Проверяем выход текущей страницы за границы доступных страниц
	$scope.$watch('pageOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.pageOptions.value = Math.max(Math.min(newValue, $scope.pageOptions.values.length + 1), 0);
			$scope.promiseList.push(configure());
		}
	});

	// Доступные ограничения количества товаров на странице.
	var defaultLimits = ProductListControllerConfig.limitList;

	// Переменная limit хранит объект, описывающий текущее ограничение количества товаров на странице.
	// По умолчанию это первый объект из массива доступных ограничений.
	var limit = undefined;
	if (defaultLimits && defaultLimits.length > 0) {
		limit = defaultLimits[0];
	}

	// Если при переходе на состояние списка товаров был указан параметр ограничения товаров на странице, применить его.
	if ($stateParams.limit) {
		var paramLimit = Number($stateParams.limit);
		for (var key in defaultLimits) {
			if (defaultLimits[key].value === paramLimit) {
				limit = defaultLimits[key];
				break;
			}
		}
	}

	// Объект настройки ограничения количества товаров на странице.
	$scope.limitOptions = {
		value: limit,
		values: defaultLimits
	};

	// Обработчик изменения ограничения.
	$scope.$watch('limitOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.promiseList.push($q(function (resolve, reject) {
				$state.go('products.list', {
					page: undefined,
					limit: newValue.value
				}).then(function (result) {
					return configure();
				}).then(function (result) {
					return resolve() || null;
				}).catch(function (err) {
					return reject(err);
				});
			}));
		}
	});

	// Сортировка.

	// Доступные виды сортировки списка.
	var defaultSorts = ProductListControllerConfig.sortList;

	// Переменная sort хранит объект, описывающий текущую сортировку.
	// По умолчанию это первый объект из массива доступных видов сортировки списка.
	var sort = undefined;
	if (defaultSorts && defaultSorts.length > 0) {
		sort = defaultSorts[0];
	}

	// Если при переходе на состояние списка товаров был указан параметр сортировки, применить её.
	if ($stateParams.sort) {
		for (var _key in defaultSorts) {
			if (defaultSorts[_key].value === $stateParams.sort) {
				sort = defaultSorts[_key];
				break;
			}
		}
	}

	// Объект настройки сортировки.
	$scope.sortOptions = {
		value: sort, // Текущая сортировка.
		values: defaultSorts // Все виды сортировок.
	};

	// Обработчик изменения сортировки.
	$scope.$watch('sortOptions.value', function (newValue, oldValue) {
		if (newValue !== oldValue) {
			$scope.promiseList.push($q(function (resolve, reject) {
				$state.go('products.list', {
					page: undefined,
					sort: newValue.value
				}).then(function (result) {
					return configure();
				}).then(function (result) {
					return resolve() || null;
				}).catch(function (err) {
					return reject(err);
				});
			}));
		}
	});

	// Если при переходе на состояние списка товаров был указан параметр фильтрации, применить его.
	if ($stateParams.specs) {
		$scope.specificationValueItemList = __.chain($stateParams.specs).map(function (value, key) {
			return {
				propertyId: key,
				valueType: undefined,
				valueId: value,
				valueRelationType: undefined,
				valueRelationValue: undefined
			};
		}).value();
	}

	$scope.addToShoppingCart = function (product) {
		var shoppingCartElement = {
			product: product,
			price: product.price,
			quantityOffset: 1
		};
		if (!shoppingCartElement.price) {
			return $rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Цена товара не задана')
			});
		}
		ShoppingCartService.createOrUpdateElement(shoppingCartElement);
	};

	$scope.quantityInCart = function (value) {
		var quantity = 0;
		if (value) {
			var shoppingCartItem = ShoppingCartService.getElement(value, value.feature);
			quantity = shoppingCartItem && shoppingCartItem.quantity || 0;
		}
		return quantity;
	};

	$scope.onSpecificationChange = function (value) {
		$scope.promiseList.push($q(function (resolve, reject) {
			$scope.specificationValueItemList = value;
			$state.go('products.list', {
				page: undefined,
				specs: function () {
					var result = __.transform(value, function (result, value) {
						__.set(result, value.propertyId, value.valueId);
					}, {});
					if (__.isEmpty(result)) {
						result = undefined;
					}
					return result;
				}()
			}).then(resolve).catch(reject);
		}));
	};

	$scope.$on('login', function (event, data) {
		$scope.promiseList.push(configure());
	});

	$scope.$on('logout', function (event) {
		$scope.promiseList.push(configure());
	});

	$scope.promiseList.push(configure());
}]);