'use strict';

(function () {
	var COMPONENT_NAME = 'nwProductFeatureExtraPropertyList';

	angular.module('app').component(COMPONENT_NAME, {
		templateUrl: getCdnHrefPrefix() + 'core/shared/nw-product-feature-extra-property-list/nw-product-feature-extra-property-list.template.html',
		bindings: {
			productItem: '<',
			onFeatureChange: '='
		},
		controller: function controller($scope, $element, $attrs, __) {
			var ctrl = this;

			var configure = function configure(productItem) {
				// Преобразовываем список характеристик товара в список свойств и значений для выбора пользователю
				ctrl.featureExtraPropertyList = __.chain(productItem.features).map('extraProperties').flatten().uniqBy(function (value) {
					return value.propertyId + value.valueId;
				}).groupBy('propertyId').values().transform(function (result, value) {
					var property = __.head(value).property;
					var valueList = __.chain(value).map(function (value) {
						var extraPropertyValue = value.value;
						if (extraPropertyValue) {
							return extraPropertyValue;
						} else {
							return {
								id: value.valueId,
								elementType: 0,
								name: __.toString(value.valueId),
								ownerId: property.id,
								ownerType: 'OrmExtraProperty',
								priority: null,
								valueId: value.valueId
							};
						}
					}).forEach(function (value) {
						value.enabled = true;
					}).orderBy(['priority', 'name'], ['ASC', 'ASC']).value();
					var currentValue = null;
					if (valueList.length === 1) {
						currentValue = __.head(valueList);
					} else {
						currentValue = {
							id: null,
							elementType: 0,
							name: 'Не выбрано',
							ownerId: null,
							ownerType: null,
							priority: 0,
							valueId: null,
							enabled: true
						};
						valueList.unshift(currentValue);
					}
					result.push({
						property: property,
						valueList: valueList,
						currentValue: currentValue
					});
				}, []).value();
			};
			ctrl.$onChanges = function (changesObj) {
				var productItemChanges = changesObj.productItem;
				if (productItemChanges.currentValue !== productItemChanges.previousValue) {
					var productItem = productItemChanges.currentValue;
					ctrl.productItem = productItem;
					configure(productItem);
				}
			};
			ctrl.onFeatureExtraPropertyChangeEvent = function (value) {
				// Отбираем только заполненные свойства
				var pickedFeatureExtraPropertyList = __.filter(ctrl.featureExtraPropertyList, 'currentValue.id');
				// Из заполненых свойств формируем массив хэшей, для дальнейшего сравнения с хэшами характеристик
				var pickedFeatureExtraPropertyHashList = __.map(pickedFeatureExtraPropertyList, function (value) {
					return value.property.id + value.currentValue.id;
				});

				// Отбираем только те характиристики, значения свойств которых равны выбранным значениям
				// Получаем список характеристик, которые можно получить из выбранных свойств
				var suitableFeatureList = __.filter(ctrl.productItem.features, function (value) {
					return __.chain(value.extraProperties || []).map(function (value) {
						return value.propertyId + value.valueId;
					}).intersection(pickedFeatureExtraPropertyHashList).size().value() === pickedFeatureExtraPropertyHashList.length;
				});

				// Формируем массив хэшей для сравнения с выбранными значениями
				var suitableFeatureExtraPropertyHashList = __.chain(suitableFeatureList).map('extraProperties').flatten().map(function (value) {
					return value.propertyId + value.valueId;
				}).value();

				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = ctrl.featureExtraPropertyList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var featureExtraProperty = _step.value;

						var property = featureExtraProperty.property;
						var _iteratorNormalCompletion2 = true;
						var _didIteratorError2 = false;
						var _iteratorError2 = undefined;

						try {
							for (var _iterator2 = featureExtraProperty.valueList[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
								var valueItem = _step2.value;

								// Если идентификатор значения нулевой - значит это стандартное поле(например, "Не выбрано")
								// Такие поля всегда доступны, поэтому пропускаем
								if (!valueItem.id) {
									continue;
								}
								// Ищем хэш текущего значения свойства в отфильтрованных характеристиках, если нашли
								// Это значит, что данное значение можно выбрать, если не найдено, то блокируем
								var enabled = suitableFeatureExtraPropertyHashList.indexOf(property.id + valueItem.id) > -1;
								// Если выбрано только значение одного свойства, то не ограничиываем выбор значений этого свойства
								// Например, если нужно выбрать размер и цвет, пользователь выбирает размер который закончился
								// В таком случае, чтобы выбрать другой размер пользователю сначала нужно выбрать у размера "Не выбрано"
								if (!enabled && pickedFeatureExtraPropertyList.length === 1) {
									var currentValueFeatureExtraProperty = __.head(pickedFeatureExtraPropertyList);
									enabled = currentValueFeatureExtraProperty.property.id === property.id;
								}
								valueItem.enabled = enabled;
							}
						} catch (err) {
							_didIteratorError2 = true;
							_iteratorError2 = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion2 && _iterator2.return) {
									_iterator2.return();
								}
							} finally {
								if (_didIteratorError2) {
									throw _iteratorError2;
								}
							}
						}
					}

					// Если количество выбранных свойств равно общему количеству доступных свойств,
					// Значит нужная характеристика найдена
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				var feature = undefined;
				if (pickedFeatureExtraPropertyList.length === ctrl.featureExtraPropertyList.length) {
					feature = __.head(suitableFeatureList);
				}
				if (ctrl.onFeatureChange) {
					ctrl.onFeatureChange(feature);
				}
			};
		}
	});
})();