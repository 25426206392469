'use strict';

(function () {
	angular.module('app').directive('nwPagination', ['$state', 'cdn', function ($state, cdn) {
		return {
			restrict: 'E',
			scope: {
				page: '=',
				pageOptions: '<',
				simpleModeCallback: '<',
				dontUseCdn: '<'
			},
			compile: function compile(tElement, tAttrs, transclude) {
				return {
					pre: function preLink(scope, element, attrs, controller) {
						var cdnUrl = '';
						if (!attrs.hasOwnProperty('dontUseCdn')) {
							cdnUrl = cdn.url;
						}
						// Режим - либо формирования ссылок через ui-sref (по умолчанию), либо простое изменение значения page.
						if (attrs['simpleMode'] === 'true') {
							scope.templateUrl = cdnUrl + 'core/shared/nw-pagination/nw-pagination-simple-mode.template.html';
						} else {
							scope.state = $state;
							scope.templateUrl = cdnUrl + 'core/shared/nw-pagination/nw-pagination.template.html';
						}
					}
				};
			},
			template: '<div data-ng-include="templateUrl"></div>'
		};
	}]);
})();