'use strict';

angular.module('app').directive('nwUpdateTitle', ['$rootScope', '$transitions', function ($rootScope, $transitions) {
	return {
		restrict: 'A',
		link: function link(scope, element) {
			/* eslint angular/on-watch: 0 */
			// Использовать указанный в HTML текст в качестве заголовка по умолчанию и окончания заголовка.
			var defaultPageTitle = element.text();

			// Восстанавливать исходный текст заголовка при переходе в другое состояние.
			$transitions.onStart({}, function (transition) {
				element.text(defaultPageTitle);
			});

			// Отслеживать изменения переменной для установки заголовка и применять его, если значение новое.
			$rootScope.$watch('pageTitle', function (newValue) {
				if (newValue && angular.isString(newValue)) {
					element.text(newValue + ' – ' + defaultPageTitle);
				} else {
					element.text(defaultPageTitle);
				}
			});
		}
	};
}]);