'use strict';

(function ($) {
	// http://stackoverflow.com/a/2540673
	var countTo = function countTo() {
		return $(this).each(function () {
			// Set options for current element.
			var settings = $.extend({}, countTo.defaults, {
				from: $(this).data('count-from'),
				to: $(this).data('count-to'),
				speed: $(this).data('count-speed'),
				refreshInterval: $(this).data('count-refresh-interval'),
				decimals: $(this).data('count-decimals')
			});

			// How many times to update the value, and how much to increment the value on each update.
			var loops = Math.ceil(settings.speed / settings.refreshInterval),
			    increment = (settings.to - settings.from) / loops;

			// References & variables that will change with each update.
			var self = this,
			    $self = $(this),
			    loopCount = 0,
			    value = settings.from,
			    data = $self.data('countToObj') || {};

			$self.data('countToObj', data);

			// If an existing interval can be found, clear it first.
			if (data.interval) {
				clearInterval(data.interval);
			}
			data.interval = setInterval(updateTimer, settings.refreshInterval);

			// Initialize the element with the starting value.
			render(value);

			function updateTimer() {
				value += increment;
				loopCount++;

				// Initialize the element with the current value.
				render(value);

				if (loopCount >= loops) {
					// Remove the interval.
					$self.removeData('countToObj');
					clearInterval(data.interval);

					// Initialize the element with the last value.
					value = settings.to;
				}
			}

			function render(value) {
				var formattedValue = formatter.call(self, value, settings);
				$self.text(formattedValue);
			}
		});
	};
	countTo.defaults = {
		from: 0, // The number the element should start at.
		to: 0, // The number the element should end at.
		speed: 1000, // How long it should take to count between the target numbers.
		refreshInterval: 100, // How often the element should be updated.
		decimals: 0 // The number of decimal places to show.
	};

	function formatter(value, settings) {
		return value.toFixed(settings.decimals);
	}

	angular.module('countAnimation').directive('countAnimation', function () {
		return {
			restrict: 'A',
			link: function link(scope, element) {
				element.appear(function () {
					element.find('[data-count-to]').each(function () {
						countTo.call(this);
					});
				}, {
					accY: -100
				});
			}
		};
	});
})(jQuery);