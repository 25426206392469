'use strict';

(function () {
	angular.module('natiwi.navigation-menu').directive('nwNavItem', ['menuAim', function (menuAim) {
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			require: ['^nwNavMenu'],
			// scope: true,
			bindToController: true,
			template: '<li ng-transclude></li>',
			link: {
				post: function post(scope, element, attrs) {
					menuAim.registerRow(element);
				}
			}
		};
	}]);
})();