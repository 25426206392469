'use strict';

// Предоставляет директиву, которая формирует меню в виде иерархии групп.
// Интеграция с:
//      NavigationService
// Примеры использования:
//      С использованием атрибутов для передачи параметров:
//          <div data-nw-group-tree data-nw-group-tree-scroll-bar-element-id="menu-container" data-nw-group-tree-collapse-mode="all-except-selected"></div>
//      С использованием атрибутов для передачи параметров, без scrollbar'а:
//          <div data-nw-group-tree data-nw-group-tree-scroll-bar-disabled="true" data-nw-group-tree-collapse-mode="all-except-selected"></div>
//      Минимум с использованием атрибутов для передачи параметров:
//          <div data-nw-group-tree data-nw-group-tree-scroll-bar-element-id="menu-container"></div>
//      Минимум с использованием атрибутов для передачи параметров, без scrollbar'а:
//          <div data-nw-group-tree data-nw-group-tree-scroll-bar-disabled="true"></div>
//      С использованием одного параметра-объекта, содержащего все необходимые параметры:
//          <div data-nw-group-tree="{ scrollbarElementId: 'menu-container', collapseMode: 'all-except-selected' }"></div>
//      С использованием одного параметра-объекта, содержащего все необходимые параметры, без scrollbar'а:
//          <div data-nw-group-tree="{ scrollBarDisabled: 'true', collapseMode: 'all-except-selected' }"></div>
//      Минимум с использованием одного параметра-объекта, содержащего только обязательные параметры:
//          <div data-nw-group-tree="{ scrollbarElementId: 'menu-container' }"></div>
//      Минимум с использованием одного параметра-объекта, содержащего только обязательные параметры, без scrollbar'а:
//          <div data-nw-group-tree="{ scrollBarDisabled: 'true' }"></div>
// Параметры (либо ключи в параметре-объекте, либо соответствующие атрибуты, но в kebab-case с префиксом "data-nw-group-tree-"):
//      scrollbarElementId*      - string   - Обязательный параметр, взаимоисключающий с scrollBarDisabled - идентификатор контейнера меню, в котором следует инициализировать Perfect Scrollbar, чтобы скролить меню.
//      scrollBarDisabled*       - boolean  - Обязательный параметр, взаимоисключающий с scrollbarElementId - если значение "true", либо любое другое значение, являющееся true в логическом контексте, scrollbar будет отключён.
//      collapseMode             - string   - Режим сворачивания ветвей иерархии групп при переходе в одну из групп. Доступные режимы перечислены ниже в переменной collapseModes.
// Зависимости:
//      Angular-ui-tree (https://github.com/angular-ui-tree/angular-ui-tree)
//      Perfect Scrollbar (https://github.com/noraesae/perfect-scrollbar)
//      Bootstrap (https://github.com/twbs/bootstrap).

(function () {
	angular.module('nwGroupTree', ['ui.tree']);
})();