'use strict';

angular.module('app').directive('nwIncludeReplace', ['$timeout', function ($timeout) {
	return {
		require: 'ngInclude',
		link: function link(scope, element, attrs) {
			$timeout(function () {
				element.replaceWith(element.children());
			}, 0);
		}
	};
}]);