'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	var DIRECTIVE_NAME = 'nwForm';

	angular.module('natiwi.form').directive(DIRECTIVE_NAME, ['cdn', function (cdn) {
		return {
			restrict: 'E',
			scope: {
				formTag: '@',
				modal: '@'
			},
			transclude: {
				submit: '?nwSubmit'
			},
			replace: true,
			templateUrl: cdn.url + 'core/shared/nw-form/nw-form.template.html',
			controllerAs: 'ctrl',
			controller: ['$rootScope', '$scope', '$q', 'OrmForm', 'OrmFormAnswer', function ($rootScope, $scope, $q, OrmForm, OrmFormAnswer) {
				var vm = this;

				var formItem = undefined;

				vm.submitted = false;
				vm.title = 'Не задано';
				vm.subtitle = 'Не задано';
				vm.submitTitle = 'Отправить';
				vm.afterSubmitTitle = 'Спасибо за ответ!';
				vm.afterSubmitSubtitle = 'Мы с вами свяжемся';
				vm.fieldItems = [];
				vm.modal = $scope.modal;

				var formTag = $scope.formTag;
				if (!formTag) {
					throw new Error('Идентификатор формы не задан');
				}
				OrmForm.findOne({
					filter: {
						where: {
							tag: formTag
						},
						include: 'fieldItems'
					}
				}).$promise.then(function (result) {
					if (!result) {
						return Promise.reject(new Error('Форма не найдена'));
					}

					formItem = result;

					vm.title = formItem.title;
					vm.subtitle = formItem.subtitle;
					vm.submitTitle = formItem.submitTitle;
					vm.afterSubmitTitle = formItem.afterSubmitTitle;
					vm.afterSubmitSubtitle = formItem.afterSubmitSubtitle;
					vm.fieldItems = (formItem.fieldItems || []).map(function (value) {
						var answerType = undefined;
						var answerId = null;
						var answerMetadata = undefined;
						switch (value.fieldTypeId) {
							case 'form_field_types.short_text':
							case 'form_field_types.long_text':
							case 'form_field_types.drop_down_list':
							case 'form_field_types.single_option':
							case 'form_field_types.email':
							case 'form_field_types.phone':
							case 'form_field_types.link':
							case 'form_field_types.label':
								answerType = 'string';
								break;
							case 'form_field_types.multiple_option':
								answerType = 'array';
								answerId = [];
								answerMetadata = {
									type: 'string'
								};
								break;
							case 'form_field_types.number':
								answerType = 'number';
								break;
							case 'form_field_types.date':
								answerType = 'date';
								break;
							case 'form_field_types.yes_no':
								answerType = 'boolean';
								answerId = false;
								break;
						}
						return {
							fieldItem: value,
							answerType: answerType,
							answerId: answerId,
							answerMetadata: answerMetadata,
							answerRelationType: null,
							answerRelationValue: null
						};
					});
				}).catch(function (err) {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
				});

				var multipleOptionFnCache = {};
				vm.createMultipleOptionFn = function (answerFieldItem, value) {
					var key = '' + answerFieldItem.fieldItem.id + value;
					var fn = multipleOptionFnCache[key];
					if (!fn) {
						fn = function fn(checked) {
							var valueList = answerFieldItem.answerId;
							if (arguments.length === 0) {
								return valueList.indexOf(value) > -1;
							} else {
								var index = valueList.indexOf(value);
								if (checked) {
									if (index === -1) {
										valueList.push(value);
									}
								} else {
									if (index > -1) {
										valueList.splice(index, 1);
									}
								}
							}
						};
						multipleOptionFnCache[key] = fn;
					}
					return fn;
				};

				vm.submit = function () {
					return $q(function (resolve, reject) {
						if (!formItem) {
							return Promise.reject(new Error('Форма не задана'));
						}
						if (!vm.fieldItems) {
							return Promise.reject(new Error('Поля не заданы'));
						}
						if (vm.fieldItems.length === 0) {
							return Promise.reject(new Error('Поля пустые'));
						}
						var _iteratorNormalCompletion = true;
						var _didIteratorError = false;
						var _iteratorError = undefined;

						try {
							for (var _iterator = vm.fieldItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
								var answerFieldItem = _step.value;

								var fieldItem = answerFieldItem.fieldItem;
								if (fieldItem.required) {
									if (!answerFieldItem.answerId || fieldItem.typeId === 'value_metadata_types.relation' && !answerFieldItem.answerRelationType && !answerFieldItem.answerRelationValue) {
										return Promise.reject(new Error('\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \'' + fieldItem.name + '\' \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u043E'));
									}
								}
							}
						} catch (err) {
							_didIteratorError = true;
							_iteratorError = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion && _iterator.return) {
									_iterator.return();
								}
							} finally {
								if (_didIteratorError) {
									throw _iteratorError;
								}
							}
						}

						OrmFormAnswer.process({
							formId: formItem.id,
							fieldItemList: vm.fieldItems.map(function (value) {
								return {
									fieldItemValue: value.fieldItem,
									answerType: value.answerType,
									answerId: value.answerId,
									answerMetadata: value.answerMetadata,
									answerRelationType: value.answerRelationType,
									answerRelationValue: value.answerRelationValue
								};
							})
						}).$promise.then(function (result) {
							vm.submitted = true;

							return resolve() || null;
						}).catch(function (err) {
							$rootScope.$broadcast('notification', {
								type: 'error',
								message: err
							});
							return reject(err);
						});
					});
				};

				return vm;
			}]
		};
	}]);
})();