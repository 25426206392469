'use strict';

angular.module('app').factory('UserService', ['$rootScope', '$cookies', '$q', 'LoopBackAuth', 'OrmUser', 'OrmPartner', function ($rootScope, $cookies, $q, LoopBackAuth, OrmUser, OrmPartner) {

	$rootScope.currentUser = undefined;

	function saveToStorage() {
		if ($rootScope.currentUser) {
			localStorage.setItem('currentUser', JSON.stringify($rootScope.currentUser));
		} else {
			localStorage.removeItem('currentUser');
		}
	}

	function restoreFromStorage() {
		var result = void 0;
		var raw = localStorage.getItem('currentUser');
		if (raw) {
			result = JSON.parse(raw);
		}
		return result;
	}

	function configure() {
		$rootScope.currentUser = restoreFromStorage();
		authorize();
		if ($rootScope.currentUser) {
			fetch();
		}
	}

	function authorize() {
		return $q(function (resolve, reject) {
			var currentUserId = $cookies.get('userId');
			var accessTokenId = $cookies.get('access_token');
			if (!$rootScope.currentUser && currentUserId && accessTokenId) {
				currentUserId = currentUserId.split('.')[0];
				currentUserId = currentUserId.split(':')[1];

				accessTokenId = accessTokenId.split('.')[0];
				accessTokenId = accessTokenId.split(':')[1];

				persist({
					id: accessTokenId,
					userId: currentUserId,
					user: {
						id: currentUserId
					}
				}, true);

				fetch().then(function (result) {
					return resolve($rootScope.currentUser) || null;
				}).catch(function (err) {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
					return reject(err);
				});
			} else {
				return resolve();
			}
		});
	}

	function login(email, password, rememberMe) {
		return $q(function (resolve, reject) {
			OrmUser.login({
				rememberMe: false,
				include: 'user'
			}, {
				email: email,
				password: password
			}).$promise.then(function (result) {
				persist(result, rememberMe);

				return OrmPartner.findOne({
					filter: {
						where: {
							id: result.user.principalId
						},
						include: 'loyaltyCards'
					}
				}).$promise;
			}).then(function (result) {
				$rootScope.currentUser.principal = result;
				saveToStorage();
				$rootScope.$broadcast('login', $rootScope.currentUser);
				$rootScope.$broadcast('notification', {
					type: 'success',
					message: 'Добро пожаловать'
				});
				return resolve($rootScope.currentUser) || null;
			}).catch(function (err) {
				return reject(err);
			});
		});
	}

	function logout() {
		return $q(function (resolve, reject) {
			OrmUser.logout().$promise.then(function (err) {
				clear();
				saveToStorage();
				$rootScope.$broadcast('logout');
				return resolve() || null;
			}).catch(function (err) {
				clear();
				saveToStorage();
				$rootScope.$broadcast('logout');
				return reject(err);
			});
		});
	}

	function resetPassword(email) {
		return $q(function (resolve, reject) {
			OrmUser.resetPassword({
				email: email
			}).$promise.then(function () {
				$rootScope.$broadcast('resetPassword', $rootScope.currentUser);
				return resolve() || null;
			}).catch(function (err) {
				return reject(err);
			});
		});
	}

	function confirmResetPassword(userId, accessTokenId, newPassword) {
		return $q(function (resolve, reject) {
			LoopBackAuth.accessTokenId = accessTokenId;
			LoopBackAuth.userId = userId;
			OrmUser.findById({
				id: LoopBackAuth.userId
			}).$promise.then(function (result) {
				return OrmUser.updateAttributes({
					id: result.id
				}, {
					'password': newPassword
				}).$promise;
			}).then(function (result) {
				LoopBackAuth.accessTokenId = undefined;
				LoopBackAuth.userId = undefined;
				$rootScope.$broadcast('confirmResetPassword', $rootScope.currentUser);
				return resolve() || null;
			}).catch(function (err) {
				LoopBackAuth.accessTokenId = undefined;
				LoopBackAuth.userId = undefined;
				return reject(err);
			});
		});
	}

	function register(email, password, firstName, lastName, partnerTypeId, anonymous) {
		return $q(function (resolve, reject) {
			OrmUser.process({
				email: email,
				password: password,
				partnerTypeId: partnerTypeId,
				anonymous: anonymous,
				autoLogin: true,
				firstName: firstName,
				lastName: lastName
			}).$promise.then(function (result) {
				var accessToken = result.accessTokens[0];
				delete result.accessTokens;
				accessToken.user = result;
				persist(accessToken, true);

				return OrmPartner.findOne({
					filter: {
						where: {
							id: accessToken.user.principalId
						},
						include: 'loyaltyCards'
					}
				}).$promise;
			}).then(function (result) {
				$rootScope.currentUser.principal = result;
				saveToStorage();
				$rootScope.$broadcast('register', $rootScope.currentUser);
				return resolve($rootScope.currentUser) || null;
			}).catch(function (err) {
				return reject(err);
			});
		});
	}

	function getCurrentUser() {
		return $rootScope.currentUser;
	}

	function getAccessToken() {
		return LoopBackAuth.accessTokenId;
	}

	function persist(accessToken, rememberMe) {
		$rootScope.currentUser = accessToken.user;
		LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user);
		LoopBackAuth.rememberMe = rememberMe;
		LoopBackAuth.save();
		saveToStorage();
	}

	function clear() {
		LoopBackAuth.clearUser();
		LoopBackAuth.clearStorage();
		$cookies.remove('userId', {
			path: '/'
		});
		$cookies.remove('access_token', {
			path: '/'
		});
		$rootScope.currentUser = undefined;
		saveToStorage();
	}

	function fetch() {
		return $q(function (resolve, reject) {
			if ($rootScope.currentUser) {
				return OrmUser.findById({
					id: $rootScope.currentUser.id,
					filter: {
						include: {
							relation: 'principal',
							scope: {
								include: 'loyaltyCards'
							}
						}
					}
				}).$promise.then(function (result) {
					$rootScope.currentUser = result;
					saveToStorage();
					return resolve() || null;
				}).catch(function (err) {
					return reject(err);
				});
			}
			return resolve();
		});
	}

	configure();

	return {
		login: login,
		logout: logout,
		register: register,
		resetPassword: resetPassword,
		confirmResetPassword: confirmResetPassword,
		getCurrentUser: getCurrentUser,
		getAccessToken: getAccessToken,
		fetch: fetch,
		persist: persist,
		clear: clear
	};
}]);