'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwDateSmart';

	// Объявление функций здесь позволит объявить их один раз для всех директив благодаря области видимости анонимной функции, что позволит сэкономить память в случае с множественным использованием директивы.

	// Возвращает суффикс к слову "минут" в зависимости от указанного количества минут.
	// Чтобы избежать лишних вычислений при каждом вызове данной функции, подразумевается, что значение mins < 100.
	// Поэтому в случае большего кол-ва минут, вызывающий код должен вычленять только единицы и десятки, только они имеют значение
	// и если этого не сделать, бОльшие значения будут возвращать неверное окончание для чисел, оканчивающихся на 11, 12, 13, 14.
	// Вычленить единицы и десятки можно например так: mins % 100.
	var getSuffixMins = function getSuffixMins(mins) {
		// let rest = mins % 100;
		var suffixMins = '';

		if (mins === 1) {
			suffixMins = 'у';
		} else if (mins < 5) {
			suffixMins = 'ы';
		} else if (mins > 20) {
			var restUnits = mins % 10;

			if (restUnits === 1) {
				suffixMins = 'у';
			} else if (restUnits < 5) {
				suffixMins = 'ы';
			}
		}

		return suffixMins;
	};

	// Функция для ручной локализации коротких имён месяцев.
	// Подключать библиотеку локализации Angular (angular-i18n) не стоит, так как она бы использовалась только в одной строке данной директивы,
	// а загрузка дополнительной библиотеки замедлила бы загрузку сайта.
	var getShortMonthLocalizedName = function getShortMonthLocalizedName(monthValue) {
		switch (monthValue) {
			case 0:
				return 'янв';
			case 1:
				return 'фев';
			case 2:
				return 'мар';
			case 3:
				return 'апр';
			case 4:
				return 'май';
			case 5:
				return 'июн';
			case 6:
				return 'июл';
			case 7:
				return 'авг';
			case 8:
				return 'сен';
			case 9:
				return 'окт';
			case 10:
				return 'ноя';
			case 11:
				return 'дек';
		}
	};

	angular.module('dateTime').directive(DIRECTIVE_NAME, function ($filter) {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];

				scope.$watch(attributeValue, function (newValue) {
					if (newValue && angular.isString(newValue)) {
						var date = new Date(newValue),
						    now = new Date(),
						    deltaMins = Math.floor((now - date) / 1000 / 60),
						    dateFormat = void 0,
						    dateStringFormated = void 0;

						if (deltaMins < 1) {
							dateStringFormated = 'Только что';
						} else if (deltaMins < 60) {
							// Разница менее часа.
							// Вычисление окончания минут.
							var suffixMins = getSuffixMins(deltaMins);

							dateStringFormated = deltaMins + ' \u043C\u0438\u043D\u0443\u0442 ' + suffixMins + ' \u043D\u0430\u0437\u0430\u0434';
						} else {
							// Разница более часа.
							var dateYear = date.getFullYear(),
							    nowYear = now.getFullYear();

							if (dateYear !== nowYear) {
								// Другой год.
								dateFormat = 'yyyy.MM.dd HH:mm:ss';

								dateStringFormated = $filter('date')(newValue, dateFormat);
							} else {
								var dateMonth = date.getMonth(),
								    nowMonth = now.getMonth(),
								    dateDay = date.getDay(),
								    nowDay = now.getDay();

								if (dateMonth !== nowMonth || dateDay !== nowDay) {
									// Другой месяц или другой день.
									dateFormat = 'dd ' + getShortMonthLocalizedName(dateMonth) + ' HH:mm:ss';

									dateStringFormated = $filter('date')(newValue, dateFormat);
								} else {
									dateFormat = 'HH:mm:ss';

									dateStringFormated = $filter('date')(newValue, dateFormat);
								}
							}
						}

						element.text(dateStringFormated);
					}
				});
			}
		};
	});
})();