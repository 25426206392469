'use strict';

// Provides directive that searchs for the elements with the data-count-to attribute
// in the element that has data-count-animation attribute,
// then adds appear handlers to each founded element.
// Each hangler gets attrubutes from corresponding elements
// and uses it as a options to animate count from and to the specified values
// with the specified speed and refresh interval values.
// As an attrubutes there is also can be specified desired number of decimals.
// For more details see the directive code which is short.
// External dependencies:
//      https://github.com/jquery/jquery (tested with version 1.11.3)
//      https://github.com/bas2k/jquery.appear (at the time of release, there was only one version)
// Usage example:
/*
<section data-count-animation>
	<span data-count-speed="8000" data-count-to="12">12</span>
	<span data-count-speed="5000" data-count-from="1000" data-count-to="1140">1140</span>
	<span data-count-speed="750" data-count-to="2">2</span>
</section>
*/

(function () {
	if (!window.jQuery) console.error('header-list-fade-in AngularJS 1 module requires jquery plugin.');
	if (!window.jQuery.fn.appear) console.error('header-list-fade-in AngularJS 1 module requires jquery.appear.bas2k plugin.');

	angular.module('countAnimation', []);
})();