'use strict';

// Предоставляет директиву, которая выполняет скроллинг до верха страницы, либо до элемента CSS-селектором, указанным в значении атрибута.
// Если в результате выборки по селектору элементов несколько, итоговое значение зависит от работы функции jQuery offset(),
// см. документацию по используемой версии jQuery (обычно берётся первый элемент).
// Используются:
//      jQuery (https://github.com/jquery/jquery-dist)
// Примеры использования:
//      Медленный переход на самый верх:
//      <div data-scroll-to>Наверх</div>
//      Скроллинг на самый верх за 200 мс:
//      <div data-scroll-to data-scroll-to-speed="200">Наверх</div>
//      Медленный переход к элементу с указанным CSS-селектором:
//      <div data-scroll-to=".css-class-name">Перейти</div>
//      Переход к элементу с указанным CSS-селектором за 1000 мс:
//      <div data-scroll-to=".css-class-name" data-scroll-to-speed="1000">Перейти</div>
// Параметры (атрибуты в kebab-case с префиксом "data-scroll-to-"):
//      speed      - number   - Скорость анимации в миллисекундах. Должно быть положительным. Если не указано, используется значение по умолчанию, соответствующее константе "slow", определённой в jQuery. 

(function () {
	angular.module('scrollTo', []);
})();