'use strict';

angular.module('app').factory('NotificationService', ['$rootScope', 'toastr', function ($rootScope, toastr) {
	/* eslint angular/on-watch: 0 */
	var DEFAULT_TTL = 5000;

	var notify = function notify(notice) {
		if (!notice) {
			console.log('Notification service: notice empty');
			return;
		}
		if (!notice.message) {
			console.log('Notification service: notice message empty');
			return;
		}
		notice.type = notice.type || 'info';
		notice.ttl = notice.ttl || DEFAULT_TTL;

		if (notice.message instanceof Error) {
			notice.message = notice.message && notice.message.message || 'Неизвестная ошибка';
			console.log(notice.message);
		} else if (angular.isObject(notice.message)) {
			var error = notice.message.data && notice.message.data.error || notice.message;
			if (error.code === 'LOGIN_FAILED') {
				notice.message = 'Неверный адрес e-mail или пароль. Проверьте, нет ли опечаток.';
			} else if (error.code === 'AUTHORIZATION_REQUIRED') {
				notice.message = 'Требуется авторизация.';
			} else if (error.code === 'INVALID_TOKEN') {
				notice.message = 'Неверный токен доступа, пожалуйста переавторизуйтесь.';
			} else if (error.code === 'ACCESS_DENIED') {
				notice.message = 'Доступ запрещен.';
			} else if (error.code === 'USERNAME_EMAIL_REQUIRED') {
				notice.message = 'Требуется имя пользователя или адрес электронной почты.';
			} else {
				switch (error.name) {
					case 'ValidationError':
						var messages = error.details.messages;
						for (var k in messages) {
							notice.message = messages[k][0];
							break;
						}
						break;
					default:
						notice.message = error.message || 'Неизвестная ошибка';
						break;
				}
			}
		}

		switch (notice.type) {
			case 'success':
				toastr.success(notice.message, 'Успешно', {
					timeOut: notice.ttl
				});
				break;
			case 'error':
				toastr.error(notice.message, 'Ошибка');
				break;
			case 'exception':
				toastr.error(notice.message, 'Ошибка');
				break;
			case 'info':
				toastr.info(notice.message, 'Информация');
				break;
			case 'warning':
				toastr.warning(notice.message, 'Внимание');
				break;
			default:
				toastr.info(notice.message, 'Информация');
		}
	};

	$rootScope.$on('notification', function (event, notice) {
		notify(notice);
	});

	return {
		notify: notify
	};
}]);