'use strict';

angular.module('app').factory('$history', function ($state) {
	var history = [];

	return {
		push: function push(state, params) {
			history.push({
				state: state,
				params: params
			});
		},
		all: function all() {
			return history;
		},
		go: function go(step) {
			var prev = this.previous(step || -1);
			return $state.go(prev.state, prev.params);
		},
		previous: function previous(step) {
			return history[history.length - Math.abs(step || 1)];
		},
		back: function back() {
			return this.go(-1);
		}
	};
}).run(function ($history, $state, $transitions) {
	/* eslint angular/no-run-logic: 0 */
	$transitions.onSuccess({}, function (transition) {
		var from = transition.from();
		var fromParams = transition.params('from');
		if (!from.abstract) {
			$history.push(from, fromParams);
		}
	});
	$history.push($state.current, $state.params);
});