'use strict';

(function () {
	angular.module('loaderBlock').directive('loaderBlock', ['$transitions', '$templateCache', function ($transitions, $templateCache) {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				var properties = scope.$eval(attrs.loaderBlock);

				$transitions.onStart({}, function (transition) {
					var toState = transition.targetState().state();
					var templateUrl = toState.templateUrl;

					// Если в новом состоянии указан templateUrl
					if (templateUrl) {
						var cache = $templateCache.get(templateUrl);

						// и этот шаблон уже закеширован,
						if (cache && typeof cache[1] === 'string') {
							// скрыть экран-загрузчик.
							element.css('display', 'none');
						} else {
							// Если же шаблон не закеширован, отобразить загрузчик.
							element.css('display', 'block');
						}
					} else {
						// Если же в новом состоянии нет templateUrl, но есть views, пройтись по ним всем
						for (var viewKey in toState.views) {
							// и если среди них есть те, у которых указан templateUrl,
							if (toState.views[viewKey].templateUrl) {
								// проверить, закеширован ли каждый шаблон.
								var _cache = $templateCache.get(toState.views[viewKey].templateUrl);

								// Если хотя бы один из шаблонов views, используемых в state'е, не закеширован,
								if (!_cache || typeof _cache[1] !== 'string') {
									// отобразить экран-загрузчик вплоть до того, как обработчик успешной смены состояния плавно скроет его.
									element.css('display', 'block');

									return; // Одного незакешированного шаблона достаточно, чтобы показывать обработчик.
								}
							}
						}

						// Если все шаблоны, используемые во views закешированы, убрать экран-загрузчик.
						element.css('display', 'none');
					}
				});

				$transitions.onSuccess({}, function (transition) {
					element.delay(properties.delay).fadeOut();
				});
			}
		};
	}]);
})();