'use strict';

(function () {
	angular.module('natiwi.navigation-menu').directive('nwNavTrigger', [function () {
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			require: ['^nwNavMenu'],
			bindToController: true,
			template: '<ng-transclude></ng-transclude>',
			link: function link(scope, element, attrs, ctrls, transclude) {
				scope.navMenuCtrl = ctrls && ctrls.length && ctrls[0];
			}
		};
	}]);
})();