'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwOnFinishRepeatRender';
	angular.module('app').directive(DIRECTIVE_NAME, ['$timeout', function ($timeout) {
		return {
			restrict: 'A',
			link: function link(scope, element, attr) {
				if (scope.$last === true) {
					$timeout(function () {
						scope.$emit('ngRepeatFinished', attr.nwOnFinishRepeatRender);
					});
				}
			}
		};
	}]);
})();