'use strict';

// Директива позволяет реализовать поиск по списку товаров.
// Инструкция:
//      1. Добавить в элемент form поиска директиву 'data-nw-product-search'. Если элемента form у поиска нет - создать.
//          При необходимости инициализации директивы с помощью доступных параметров, необходимо в значении директивы data-nw-product-search передать в текстовом виде объект JavaScript,
//          где ключи это имена параметров, а значения это соответственно значения для параметров. Список доступных параметров инициализации указан ниже. Пример передачи объекта:
//              <form data-nw-product-search="{ defaultSearchFieldIsShown: true }"><input type="text"><button type="submit">Искать!</button></form>
//      2. Проверить, что поле для ввода это элемент input и у него type="text". По этим признакам директива будет искать поле ввода внутри элемента с данной директивой для получения строки запроса,
//          а так же для его фокусировки при появлении.
//      3. Добавить в элемент form следующий атрибут со следующим значением:
//              data-ng-submit="search()"
//          Это необходимо, чтобы по нажатию button с типом submit, что внутри формы,
//          либо по нажатию Enter в то время когда поле ввода строки запроса сфокусировано,
//          происходил переход к результатам поиска.
//          Конечно можно обойтись и без этого, если просто добавить на кнопку, либо на другой элемент внутри формы следующий атрибут со следующим значением:
//              data-ng-click="search()"
//      4. В случае, если в шаблоне поле поиска должно скрываться/показываться, использовать для этого флаг "searchFieldIsShown" в стандартных директивах AngularJS: data-ng-hide, data-ng-show, data-ng-click, например:
//              <a data-ng-hide="searchFieldIsShown" data-ng-click="searchFieldIsShown = true">Показать строку поиска. При нажатии этот элемент исчезнет.</a>.
//          Поле ввода строки запроса фокусируется автоматически как только значение поля searchFieldIsShown === true.
// Доступные параметры инициализации:
//      defaultSearchFieldIsShown      - boolean    - значение поля searchFieldIsShown по умолчанию. Если не указан, значение по умолчанию false.
// Пример подключения директивы:
//      <form data-nw-product-search="{ defaultSearchFieldIsShown: true }"><input type="text"><button type="submit">Искать!</button></form>

(function () {
	var DIRECTIVE_NAME = 'nwProductSearch';

	angular.module('app').directive(DIRECTIVE_NAME, ['$rootScope', '$state', '$timeout', function ($rootScope, $state, $timeout) {
		return {
			scope: {
				attributeObject: '<' + DIRECTIVE_NAME // проброшен объект, указанные в значении атрибута директивы (парсится автоматически Angular'ом).
			},
			link: function link(scope, element) {
				// Выполняет переход к состоянию списка товаров, соответствующих строке запроса, указанной в переменной searchText в корневом scope.
				// Корневой scope используется вместо scope элемента, потому что иногда возникает необходимость доступиться к значению строки запроса извне.
				// При этом параметры группы и страницы сбрасываются.
				// После перехода строка запроса очищается.
				if (!scope.$parent.search) {
					scope.$parent.search = function () {
						$state.go('products.list', {
							group: null,
							page: null,
							specs: null,
							q: $rootScope.searchText
						});

						// Сообщить всем экземплярам данной директивы о выполнении перехода к результатам поиска.
						scope.$parent.$broadcast('search-performed');

						// Скрыть поля ввода всех строк запроса (не имеет значения, если поле searchFieldIsShown не используется).
						scope.$parent.searchFieldIsShown = false;
					};
				}
				// Обработчик события выполнения перехода к результатам поиска.
				scope.$on('search-performed', function () {
					// Очистить поле запроса после перехода к результатам поиска.
					getInputElement().value = '';
				});

				// Элемент ввода строки запроса и метод его получения.
				var inputElement = null,
				    getInputElement = function getInputElement() {
					if (!inputElement) {
						inputElement = element[0].querySelector('input[type=search]');
					}

					return inputElement;
				};
				// Обработчик изменения значения строки запроса в соответствующем элементе.
				scope.$watch(function () {
					return getInputElement().value;
				}, function (newValue) {
					// Сохранить строку запроса в корневом scope, это нужно для возможности доступиться к строке запроса извне.
					$rootScope.searchText = newValue;
				});

				// Флаг - признак отображения меню. При необходимости может использоваться в стандартных директивах data-nd-show, data-ng-hide для отображения и скрытия элементов страницы,
				// а так же может меняться с помощью стандартной директивы data-ng-click.
				scope.$parent.searchFieldIsShown = false;
				if (scope.attributeObject && scope.attributeObject.hasOwnProperty('defaultSearchFieldIsShown')) {
					scope.$parent.searchFieldIsShown = scope.attributeObject.defaultSearchFieldIsShown;
				}
				// Обработчик события смены признака отображения меню. Фокусирует элемент ввода строки запроса, когда она отображается.
				scope.$parent.$watch('searchFieldIsShown', function (newValue) {
					if (newValue === true) {
						// После окончания отрисовки элементов
						$timeout(function () {
							// найти input типа text и сфокусировать его.
							getInputElement().focus();
						});
					}
				});
			}
		};
	}]);
})();