'use strict';

(function ($) {
	angular.module('headerListFadeIn').directive('headerListFadeIn', function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Get object from attribute value string.
				var properties = scope.$eval(attrs.headerListFadeIn);

				var index = 0,
				    headerElement = element.find(properties.header),
				    cthis = element.find(properties.list).children();

				// Set initial opacity to 0.
				headerElement.css('opacity', 0);
				cthis.css('opacity', 0);

				// Element appear handler.
				element.appear(function () {
					// Fade in headline and then li elements.
					headerElement.animate({
						opacity: 1
					}, properties.headerDelay, function () {
						if (cthis.length > index) {
							fadeContent(cthis, properties.listElementsDelay);
						}
					});
				}, {
					accY: -100
				});

				function fadeContent(cthis, delay) {
					$(cthis[index]).animate({
						opacity: 1
					}, delay).promise().done(function () {
						if (cthis.length > ++index) {
							fadeContent(cthis, delay);
						}
					});
				}
			}
		};
	});
})(jQuery);