'use strict';

(function () {
	angular.module('natiwi.core.cdn')
	// Позволяет определить префикс пути к файлам шаблонов, загружаемых директивами.
	// Префиксом является корень абсолютного URL центрального хранилища файлов ядра, если сайт не открыт локально и это не отладка ядра (для последнего необходимо сохранять оригинальное имя папки локального репозитория - 'natiwi-cms-client'). В остальных случаях префикс - пустая строка, что означает использование локальных файлов вместо файлов с CDN.
	.provider('cdn', function () {
		this.$get = function () {
			return {
				url: getCdnHrefPrefix()
			};
		};
	});
})();

var getCdnHrefPrefixCachedResult = void 0;

function getCdnHrefPrefix() {
	if (getCdnHrefPrefixCachedResult !== undefined) {
		return getCdnHrefPrefixCachedResult;
	}

	// Автоматическая проверка - производится ли отладка natiwi-cms-client (в будущем можно сделать проверку менее костыльной),
	if ((location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '') && location.pathname.indexOf('natiwi-cms-client') !== -1) {
		// в этом случае файлы должны загружаться не из CDN, а локальные.
		getCdnHrefPrefixCachedResult = '';
	} else {
		// Во всех остальных случаях должен использоваться CDN.
		getCdnHrefPrefixCachedResult = 'https://cdn.natiwi.com/natiwi-cms-client/v4/client/app/';
	}

	return getCdnHrefPrefixCachedResult;
};