'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwGroupTree';

	angular.module(DIRECTIVE_NAME).directive(DIRECTIVE_NAME, ['$q', 'NavigationService', 'cdn', function ($q, NavigationService, cdn) {
		return {
			restrict: 'A', // Привязка директивы только к атрибуту HTML-элемента.
			scope: { // Scope изолирован,
				attributeObject: '<' + DIRECTIVE_NAME, // проброшен объект, указанные в значении атрибута директивы (парсится автоматически Angular'ом).
				scrollBarDisabled: '@' + DIRECTIVE_NAME + 'ScrollBarDisabled',
				scrollBarElementId: '@' + DIRECTIVE_NAME + 'ScrollBarElementId',
				collapseMode: '@' + DIRECTIVE_NAME + 'CollapseMode'
			},
			templateUrl: cdn.url + 'core/store/products/nw-group-tree/nw-group-tree.template.html',
			link: function link(scope, element, attrs) {

				// Переменные в scope.

				// Опции ui-tree и callback'и.
				scope.treeOptions = {
					// accept: function (sourceNodeScope, destNodesScope, destIndex) {
					//     return true;
					// }
				};

				// Внутренние переменные.

				// Доступные режимы сворачивания ветвей при переходе в одну из групп.
				var collapseModes = ['all-except-selected', // Сворачивать все группы кроме той, в которой находится пользователь.
				'all', // Сворачивать все группы, включая ту, которая была выбрана.
				'none' // Не сворачивать ничего, все группы будут оставаться в том виде, в котором пользователь их оставил до тех пор, пока не будет произведена полная перезагрузка страницы.
				];

				// Объект с параметрами из значения директивы.
				var properties = scope.attributeObject;

				// Итоговое значение флага отключения scrollbar'а.
				var scrollBarDisabledChoosed = void 0;

				// Итоговый идентификатор, который будет использоваться для получения контейнера меню, чтобы инициализировать в нём scrollbar.
				var scrollbarElementIdChoosed = void 0;

				// Элемент страницы, полученный по идентификатору из переменной выше.
				var menuContainer = void 0;

				// Итоговый режим сворачивания ветвей меню при переходе в группу, либо при начальном открытии страницы с меню.
				var collapseModeChoosed = void 0;

				// Defer, promise которого будет выполнен, когда для всех узлов дерева будет сформирован scope.
				var treeNodesScopesRecievedDefer = void 0;

				// Handle таймера, в рамках которого осуществляется проверка окончания инициализации scope всех узлов.
				var checkAllNodesScopesExistsIntervalHandle = null;

				// Валидация параметров.

				if (properties && properties.hasOwnProperty('scrollBarDisabled')) {
					scrollBarDisabledChoosed = !!properties.scrollBarDisabled; // Вне зависимости от типа преобразовать к boolean.
				} else {
					scrollBarDisabledChoosed = scope.scrollBarDisabled;
				}

				if (!scrollBarDisabledChoosed) {
					scrollbarElementIdChoosed = properties && properties.scrollbarElementId || scope.scrollBarElementId;
					if (!scrollbarElementIdChoosed || !angular.isString(scrollbarElementIdChoosed)) {
						throw new Error(DIRECTIVE_NAME + ': there should be either attribute named "data-nw-group-tree-scroll-bar-element-id" with the id of element passed as value or parameter "scrollbarElementId" in the object passed as value of attribute named "data-nw-group-tree" with the id of element passed as string value.');
					}

					menuContainer = document.getElementById(scrollbarElementIdChoosed);
					if (!menuContainer) {
						throw new Error(DIRECTIVE_NAME + ': HTML элемент с идентификатором "' + scrollbarElementIdChoosed + '" не найден. Необходимо, чтобы в значении атрибута data-nw-group-tree-scroll-bar-element-id, либо в значении поля scrollbarElementId внутри объекта, переданного в текстовом виде (см. примеры в начале файла nw-group-tree.directive.js) в качестве значения атрибута data-nw-group-tree был идентификатор элемента, внутри которого находится элемент с атрибутом data-nw-group-tree.');
					}
				}
				collapseModeChoosed = properties && properties.collapseMode || scope.collapseMode;
				if (collapseModeChoosed && collapseModes.indexOf(collapseModeChoosed) === -1) {
					throw new Error(DIRECTIVE_NAME + ': invalid collapse mode value: ' + collapseModeChoosed + '. Value should be one of the following values: ' + collapseModes + '.');
				}

				// Функции в scope.

				// Обработчик выбора группы. Если в качестве параметра не передана группа, либо передан null, подразумевается выбор корневой группы.
				scope.onGroupSelected = function (selectedGroup) {
					switch (collapseModeChoosed) {
						case collapseModes[0]:
							collapseAllExceptSelected(selectedGroup);
							break;
						case collapseModes[1]:
							collapseAll();
							break;
						case collapseModes[2]:
							break;
					}
				};

				// Внутренние функции.

				// Сворачивает все ветви, а так же позволяет добавлять дополнительные обработчики события сворачивания.
				var collapseAll = function collapseAll() {
					// Сгенерировать событие, дающее команду ui-tree свернуть все узлы.
					scope.$broadcast('angular-ui-tree:collapse-all');
				};

				// Сворачивает все ветви кроме ветви до указанной группы, а так же позволяет добавлять дополнительные обработчики события сворачивания.
				var collapseAllExceptSelected = function collapseAllExceptSelected(selectedGroup) {
					collapseAll();

					if (selectedGroup) {
						expandToGroup(selectedGroup);
					}
				};

				// Разворачивает ветвь выбранной группы до самой группы.
				var expandToGroup = function expandToGroup(group) {
					group = group.$parent;

					while (group && group.expand) {
						group.expand();

						group = group.$parent;
					}
				};

				// Возвращает scope корневого объекта иерархии, созданного angular-ui-tree.
				var getTreeRootScope = function getTreeRootScope() {
					var treeElement = angular.element(element[0].querySelector('[data-ui-tree]'));
					if (treeElement) {
						var _scope = treeElement.scope();
						if (_scope) {
							return treeElement.scope().$nodesScope;
						}
					}

					return null;
				};

				// Возвращает promise, который возвращает узел дерева групп, соответствующий объекту группы с указанным id.
				var getTreeNodeByGroupId = function getTreeNodeByGroupId(groupId) {
					var defer = $q.defer();

					treeNodesScopesRecievedDefer.promise.then(function (treeRootScope) {
						var treeRootNodes = treeRootScope.childNodes();

						var recursiveSearch = function recursiveSearch(groups) {
							var result = null;

							for (var i = 0; i < groups.length; ++i) {
								var group = groups[i];

								if (group.node.id === groupId) {
									result = group;

									break;
								}

								if (group.hasChild()) {
									result = recursiveSearch(group.childNodes());

									if (result) break;
								}
							}

							return result;
						};

						defer.resolve(recursiveSearch(treeRootNodes));
					});

					return defer.promise;
				};

				// Рекурсивно проверяет, что узел и все вложенные узлы получены.
				// Возвращает false, если хотя бы один узел не получен.
				var checkAllNodesScopesExists = function checkAllNodesScopesExists(node) {
					if (!node || !node.$modelValue || node.$modelValue.$promise && node.$modelValue.$promise.$$state.status !== 1) {
						return false; // Узел ещё не инициализирован.
					}

					if (node.hasChild()) {
						var childNodes = node.childNodes();

						// Проверить все вложенные узлы.
						for (var i = 0; i < childNodes.length; ++i) {
							var childNode = childNodes[i];

							// Проверить вложенный узел и рекурсивно проверить его вложенные узлы.
							if (!checkAllNodesScopesExists(childNode)) {
								return false; // Если хотя бы один узел или его вложенный узел не получен, сразу вернуть результат, нет смысла продолжать цикл.
							}
						}

						return true; // Узел и все его вложенные узлы получены.
					} else {
						return true; // Узел получен, вложенные узлы и не подразумеваются.
					}
				};

				// Обработчики событий.

				// Дополнительный обработчик события сворачивания всех ветвей иерархии групп.
				scope.$on('angular-ui-tree:collapse-all', function () {
					// Если scroll bar не отключён,
					if (!scrollBarDisabledChoosed) {
						// сбросить позицию Perfect Scrollbar.
						var container = document.getElementById(scrollbarElementIdChoosed);
						container.scrollTop = 0;
						// Ps.update(container);
					}
				});

				// Обработчик события перехода в группу, генерируемого сервисом навигации.
				scope.$on('navigationGroupSelected', function (eventName, navigatedGroup) {
					// Если это не переход на главную страницу,
					if (navigatedGroup.id !== null) {
						// На случай повторной линковки директивы инициировать проверку наличия scope всех узлов.
						checkAllNodesScopesExistsInit();

						// получить узел иерархии групп, соответствующий группе, в которую выполнен переход.
						getTreeNodeByGroupId(navigatedGroup.id).then(function (navigatedGroupNode) {
							// Выполнить обработчик выбора группы.
							scope.onGroupSelected(navigatedGroupNode);
						});
					} else {
						// Выполнить обработчик выбора корневой группы.
						scope.onGroupSelected(null);
					}
				});

				// Инициализация.

				// Режим сворачивания ветвей при переходе в одну из групп. Если режим не указан в параметрах, по умолчанию используется первый режим из списка доступных.
				if (!collapseModeChoosed) {
					collapseModeChoosed = collapseModes[0];
				}

				// Получение иерархии групп от сервиса навигации.
				var rootGroupsPromise = NavigationService.getRootGroups();
				rootGroupsPromise.$promise.then(function (rootGroups) {
					scope.rootGroups = rootGroups;
				});

				// Получение общего количества групп.
				var groupsCount = -1,
				    groupsCountPromise = NavigationService.getGroupsCount();
				groupsCountPromise.then(function (count) {
					groupsCount = count;
				});

				// Периодически проверять состояние scope'ов узлов дерева.
				// Как только будут созданы все scope'ы всех узлов дерева, пометить соответствующий promise как выполненный.
				var checkAllNodesScopesExistsInit = function checkAllNodesScopesExistsInit() {
					if (checkAllNodesScopesExistsIntervalHandle === null) {
						treeNodesScopesRecievedDefer = $q.defer();

						checkAllNodesScopesExistsIntervalHandle = setInterval(function () {
							var treeRootScope = getTreeRootScope();

							// Если элемент не получен,
							if (treeRootScope === null) {
								// закончить периодическую проверку наличия scope'ов, это уже другая страница, на которой не подключена директива.
								clearInterval(checkAllNodesScopesExistsIntervalHandle);
								checkAllNodesScopesExistsIntervalHandle = null;
							}

							if (checkAllNodesScopesExists(treeRootScope)) {
								// Пометить promise получения scope'ов всех узлов как выполненный.
								treeNodesScopesRecievedDefer.resolve(treeRootScope);

								// Завершить работу интервала, он больше не понадобится до перезагрузки страницы.
								clearInterval(checkAllNodesScopesExistsIntervalHandle);
								checkAllNodesScopesExistsIntervalHandle = null;
							}
						}, 500);
					}
				};
				checkAllNodesScopesExistsInit();

				// Если текущая группа уже есть, развернуть ветку текущей группы до самой группы.
				var initialGroup = NavigationService.getLastParent();
				if (initialGroup) {
					getTreeNodeByGroupId(initialGroup.id).then(function (initialGroupNode) {
						// Выполнить обработчик выбора группы.
						scope.onGroupSelected(initialGroupNode);
					});
				}

				// Если scroll bar не отключён,
				if (!scrollBarDisabledChoosed) {
					// инициализировать Perfect Scrollbar.
					menuContainer.style.position = 'relative';
					// Ps.initialize(menuContainer);
				}
			}
		};
	}]);
})();