'use strict';

angular.module('app').factory('ShoppingCartService', ['$rootScope', 'OrmProduct', 'OrmLoyaltyPolicy', '$q', function ($rootScope, OrmProduct, OrmLoyaltyPolicy, $q) {
	/* eslint angular/on-watch: 0 */

	// Local letiables.
	var shoppingCart = {
		items: [],
		amount: 0,
		discount: 0,
		bonusPointBalance: 0,
		bonusPointIncome: 0,
		bonusPointOutcome: 0,
		bonusPointOutcomeLimit: 0
	};

	// Internal functions.
	function configure() {
		return fetchCart();
	}

	function saveToStorage() {
		var currentUser = $rootScope.currentUser;
		localStorage.setItem('user:' + (currentUser ? currentUser.id : 'anonymous') + ':shoppingCart', JSON.stringify(shoppingCart.items));
	}

	function restoreFromStorage() {
		var currentUser = $rootScope.currentUser;
		var result = [];
		var raw = localStorage.getItem('user:' + (currentUser ? currentUser.id : 'anonymous') + ':shoppingCart');
		if (raw) {
			result = JSON.parse(raw);
			result = result.filter(function (value) {
				return value.id;
			});
		}

		return result;
	}

	// External functions.

	var getCart = function getCart() {
		shoppingCart.items = restoreFromStorage();
		return shoppingCart;
	};

	var fetchCart = function fetchCart() {
		return $q(function (resolve, reject) {
			if (shoppingCart.items.length > 0) {
				OrmProduct.viewList({
					filter: {
						where: {
							id: {
								inq: shoppingCart.items.map(function (value) {
									return value.product && value.product.id;
								})
							}
						},
						include: [{
							relation: 'features',
							scope: {
								where: {
									id: {
										inq: shoppingCart.items.map(function (value) {
											return value.feature && value.feature.id;
										})
									}
								}
							}
						}, 'primaryImage']
					}
				}).$promise.then(function (result) {
					var _loop = function _loop(element) {
						var feature = void 0;
						var product = void 0;
						if (result.output.length > 0) {
							var item = result.output.filter(function (value) {
								if (value.id === element.product.id) {
									return value;
								}
							});
							item.length > 0 ? product = item[0] : undefined;
						}
						if (product && product.features.length > 0 && element.feature) {
							var _item = product.features.filter(function (value) {
								if (value.id === element.feature.id) {
									return value;
								}
							});
							_item.length > 0 ? feature = _item[0] : undefined;
						}
						if (product) {
							element.price = product.price;
							element.product = product;
							element.feature ? element.feature = feature : undefined;
							calculateCartElementAmount(element);
						}
					};

					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = shoppingCart.items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var element = _step.value;

							_loop(element);
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}

					calculateCartAmount();
					saveToStorage();
					$rootScope.$broadcast('productCartChanged', shoppingCart);
					return resolve() || null;
				}).catch(function (err) {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
					return reject(err);
				});
			} else {
				return resolve();
			}
		});
	};

	var getElement = function getElement(product, feature) {
		for (var key in shoppingCart.items) {
			var item = shoppingCart.items[key];
			if (feature && item.feature) {
				if (item.product.id === product.id && item.feature.id === feature.id) {
					return item;
				}
			} else {
				if (item.product.id === product.id) {
					return item;
				}
			}
		}

		return undefined;
	};

	// Проверяет наличие цены у товара в product и возвращает true в случае наличия цены.
	var checkPriceExists = function checkPriceExists(product) {
		if (product.price) {
			return true;
		}
		return false;
	};
	// Проверяет заполненность характеристик в features, если товар в product использует характеристики.
	// Возвращает true, если товар использует характеристики и они заполнены, либо если товар не использует характеристики.
	// Возвращает false только если товар использует характеристики и они не заполнены.
	var checkFeaturesFilled = function checkFeaturesFilled(product, features) {
		if (product.useFeatureOptionId !== 'use_feature_options.not_used' && !features) {
			return false;
		}

		return true;
	};

	var createElement = function createElement(data) {
		if (!data) throw new Error('Параметр data не задан.');
		if (data.quantity && data.quantity < 1) throw new Error('Количество элементов не может быть меньше 1.');

		// Проверить наличие цены у товара.
		if (!data.force && !checkPriceExists(data.product)) {
			$rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Нельзя добавить в корзину товар без цены.')
			});

			return null;
		}
		// Проверить заполненность характеристик товара.
		if (!checkFeaturesFilled(data.product, data.feature)) {
			$rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Необходимо выбрать все характеристики товара.')
			});

			return null;
		}

		var element = {
			id: (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase(),
			product: data.product,
			feature: data.feature,
			price: 0,
			quantity: data.quantity || 0,
			amount: 0
		};

		// Установить цену согласно номенклатуре и характеристике (feature).
		if (element.product.price) {
			element.price = element.product.price;
		}

		// Кол-во.
		if (data.quantityOffset) {
			element.quantity += data.quantityOffset;
		}

		// Сумма.
		calculateCartElementAmount(element);

		shoppingCart.items.push(element);
		calculateCartAmount();

		// Широковещательное уведомление о создании товара в корзине.
		if (data.notify !== false) {
			$rootScope.$broadcast('notification', {
				type: 'success',
				message: '\u0422\u043E\u0432\u0430\u0440 \'' + element.product.name + '\' \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D \u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u0443',
				ttl: 2000
			});
		}
		$rootScope.$broadcast('productCartChanged', shoppingCart);

		return element;
	};

	var updateElement = function updateElement(element, data) {
		if (!data) throw new Error('Параметр data не задан.');
		if (data.quantity && data.quantity < 1) throw new Error('Количество элементов не может быть меньше 1.');

		// Обновить product и feature из переданного объекта, т. к. они могут изменяться.
		element.product = data.product || element.product;
		element.feature = data.feature || element.feature;

		// Проверить наличие цены у товара.
		if (!data.force && !element.price && !checkPriceExists(element.product)) {
			$rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Нельзя добавить в корзину товар без цены.')
			});

			return null;
		}
		// Проверить заполненность характеристик товара.
		if (!checkFeaturesFilled(element.product, element.feature && element.feature.feature ? element.feature.feature : element.feature)) {
			// Здесь было обращение element.feature.feature, поэтому такая проверка во 2-м аргументе.
			$rootScope.$broadcast('notification', {
				type: 'error',
				message: new Error('Необходимо выбрать все характеристики товара.')
			});

			return null;
		}

		shoppingCart.items = restoreFromStorage();
		element = getElement(element.product, element.feature);

		// Установить цену согласно номенклатуре и характеристике (feature).
		if (element.product.price) {
			element.price = element.product.price;
		}

		// Кол-во.
		element.quantity = data.quantity || element.quantity;
		if (data.quantityOffset) {
			element.quantity += data.quantityOffset;
		}

		// Сумма.
		calculateCartElementAmount(element);
		calculateCartAmount();

		if (data.notify !== false) {
			$rootScope.$broadcast('notification', {
				type: 'success',
				message: '\u041A\u043E\u043B-\u0432\u043E \'' + element.product.name + '\': ' + element.quantity,
				ttl: 2000
			});
		}

		// Широковещательное уведомление о создании товара в корзине.
		$rootScope.$broadcast('productCartChanged', shoppingCart);

		return element;
	};

	var createOrUpdateElement = function createOrUpdateElement(data) {
		// Восстанавливаем корзину, т.к. она могла измениться на других вкладках
		shoppingCart.items = restoreFromStorage();

		var element = getElement(data.product, data.feature);
		if (element) {
			return updateElement(element, data);
		} else {
			return createElement(data);
		}
	};

	var removeElement = function removeElement(element) {
		shoppingCart.items = restoreFromStorage();

		element = getElement(element.product, element.feature);
		var elementIndex = shoppingCart.items.indexOf(element);
		if (elementIndex == -1) {
			return false;
		}
		shoppingCart.items.splice(elementIndex, 1);
		calculateCartAmount();

		$rootScope.$broadcast('notification', {
			type: 'warning',
			message: '\u0422\u043E\u0432\u0430\u0440 \'' + element.product.name + '\' \u0443\u0434\u0430\u043B\u0435\u043D \u0438\u0437 \u043A\u043E\u0440\u0437\u0438\u043D\u044B',
			ttl: 2000
		});
		$rootScope.$broadcast('productCartChanged', shoppingCart);

		return true;
	};

	var removeAll = function removeAll() {
		shoppingCart.items.length = 0; // Remove all items from cart array.
		calculateCartAmount();
		$rootScope.$broadcast('productCartChanged', shoppingCart);
	};

	var calculateCartAmount = function calculateCartAmount() {
		var amount = 0;
		var discount = 0;
		var _iteratorNormalCompletion2 = true;
		var _didIteratorError2 = false;
		var _iteratorError2 = undefined;

		try {
			for (var _iterator2 = shoppingCart.items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
				var item = _step2.value;

				amount += item.amount;
				discount += item.discount;
			}
		} catch (err) {
			_didIteratorError2 = true;
			_iteratorError2 = err;
		} finally {
			try {
				if (!_iteratorNormalCompletion2 && _iterator2.return) {
					_iterator2.return();
				}
			} finally {
				if (_didIteratorError2) {
					throw _iteratorError2;
				}
			}
		}

		shoppingCart.amount = Math.round(amount * 100.0) / 100.0;
		shoppingCart.discount = Math.round(discount * 100.0) / 100.0;
	};

	var calculateCartElementAmount = function calculateCartElementAmount(element) {
		element.amount = element.price * element.quantity;
		element.discount = 0;

		// Рассчитываем сумму автоматической суммы
		if (element.autoDiscountPercent) {
			element.autoDiscountAmount = element.price * element.quantity * element.autoDiscountPercent;
			element.discount += element.autoDiscountAmount;
		}
	};

	var applyLoyaltyPolicy = function applyLoyaltyPolicy(options) {
		return $q(function (resolve, reject) {
			if (shoppingCart.items.length > 0) {
				OrmLoyaltyPolicy.apply({
					fetchBonusPointBalance: options && options.fetchBonusPointBalance,
					couponCode: options && options.couponCode,
					paymentOptionId: options && options.paymentOption && options.paymentOption.id,
					productItemList: shoppingCart.items.map(function (value) {
						return {
							productId: value.product.id,
							featureId: value.feature && value.feature.id,
							unitId: value.product && value.product.unitId,
							quantity: value.quantity
						};
					})
				}).$promise.then(function (result) {
					if (options && options.fetchBonusPointBalance) {
						shoppingCart.bonusPointBalance = result.data.bonusPointBalanceConverted || 0;
					}
					shoppingCart.bonusPointIncome = 0;
					shoppingCart.bonusPointOutcomeLimit = 0;
					shoppingCart.loyaltyProgram = result && result.data && result.data.loyaltyProgram;

					var productItemList = result && result.data && result.data.productItemList;
					//рассчитываем лимиты баллов на заказ
					var _iteratorNormalCompletion3 = true;
					var _didIteratorError3 = false;
					var _iteratorError3 = undefined;

					try {
						for (var _iterator3 = productItemList[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
							var item = _step3.value;

							shoppingCart.bonusPointIncome += item.bonusPointIncomeConverted || 0;
							shoppingCart.bonusPointOutcomeLimit += item.bonusPointOutcomeLimitConverted || 0;
							var element = getElement({
								id: item.productId
							}, item.featureId && {
								id: item.featureId
							} || null);
							//рассчитываем скидки для каждой позиции и применяем к сумме корзины, также обновляем количество и стоимость позиций в корзине
							if (element) {
								element.quantity = item.quantity;
								element.price = item.price;
								element.autoDiscountPercent = item.autoDiscountPercent;
								calculateCartElementAmount(element);
							}
						}
						//рассчитываем количество баллов, которые можно потратить
					} catch (err) {
						_didIteratorError3 = true;
						_iteratorError3 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion3 && _iterator3.return) {
								_iterator3.return();
							}
						} finally {
							if (_didIteratorError3) {
								throw _iteratorError3;
							}
						}
					}

					shoppingCart.bonusPointOutcomeLimit = Math.min(shoppingCart.bonusPointBalance, shoppingCart.bonusPointOutcomeLimit) || 0;
					calculateCartAmount();
					saveToStorage();
					$rootScope.$broadcast('productCartChanged', shoppingCart);

					return resolve(result) || null;
				}).catch(function (err) {
					return reject(err);
				});
			} else {
				return resolve();
			}
		});
	};

	// Events.
	$rootScope.$on('productCartChanged', function (event, data) {
		saveToStorage();
	});

	$rootScope.$on('register', function (event, data) {
		var restoredItems = restoreFromStorage();
		for (var i = 0; i < restoredItems.length; i++) {
			var storedItem = restoredItems[i];
			var localItem = getElement(storedItem.product);
			if (localItem) {
				localItem.quantity += storedItem.quantity;
			} else {
				shoppingCart.items.push(storedItem);
			}
		}
		saveToStorage();

		configure();
	});

	$rootScope.$on('login', function (event, data) {
		var restoredItems = restoreFromStorage();
		for (var i = 0; i < restoredItems.length; i++) {
			var storedItem = restoredItems[i];
			var localItem = getElement(storedItem.product);
			if (localItem) {
				localItem.quantity += storedItem.quantity;
			} else {
				shoppingCart.items.push(storedItem);
			}
		}
		saveToStorage();

		configure();
	});

	$rootScope.$on('logout', function (event, next) {
		removeAll();
	});

	// Init.
	shoppingCart.items = restoreFromStorage();
	configure();

	return {
		getCart: getCart,
		fetchCart: fetchCart,
		getElement: getElement,
		createElement: createElement,
		updateElement: updateElement,
		createOrUpdateElement: createOrUpdateElement,
		removeElement: removeElement,
		removeAll: removeAll,
		applyLoyaltyPolicy: applyLoyaltyPolicy
	};
}]);