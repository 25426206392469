'use strict';

angular.module('app').controller('OrderListController', ['$scope', '$q', 'OrmCustomerOrder', function ($scope, $q, OrmCustomerOrder) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];
	$scope.orderList = undefined;
	$scope.orderListTotalCount = 0;

	var configure = function configure() {
		return $q(function (resolve, reject) {
			OrmCustomerOrder.viewList({
				filter: {
					include: 'status',
					order: 'createdAt DESC'
				}
			}).$promise.then(function (result) {
				var output = result && result.output || [];
				var outputInfo = result && result.outputInfo || {
					totalCount: output.length
				};

				$scope.orderList = output;
				$scope.orderListTotalCount = outputInfo.totalCount || 0;
				return resolve() || null;
			}).catch(function (err) {
				return reject(err);
			});
		});
	};

	$scope.promiseList.push(configure());
}]);