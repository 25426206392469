'use strict';

// Provides directive that searchs for the specified header element and list element
// in the element that has data-header-list-fade-in attribute,
// then sets initial opacity of this elements to 0 and adds appear handler
// which fade in elements with the specified delay values.
// External dependencies:
//      https://github.com/jquery/jquery (tested with version 1.11.3)
//      https://github.com/bas2k/jquery.appear (at the time of release, there was only one version)
// Usage example:
// <section data-header-list-fade-in="{ header: 'h2', headerDelay: 800, list: 'ul', listElementsDelay: 600 }">
//     <div class="container">
//         <h1>Usual header</h1>
//         <h2>Fade in header</h2>
//         <ul>
//             <li>
//                 First fade-in list item. This item will appear after appearing of the header.
//             </li>
//             <li>
//                 Second fade-in list item. This item will appear after appearing of the first fade-in list item.
//             </li>
//             <li>
//                 Third fade-in list item. This item will appear after appearing of the second fade-in list item.
//             </li>
//         </ul>
//     </div>
// </section>

(function () {
	if (!window.jQuery) console.error('header-list-fade-in AngularJS 1 module requires jquery plugin.');
	if (!window.jQuery.fn.appear) console.error('header-list-fade-in AngularJS 1 module requires jquery.appear.bas2k plugin.');

	angular.module('headerListFadeIn', []);
})();