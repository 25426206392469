'use strict';

angular.module('app').controller('UserController', ['$q', '$scope', '$rootScope', '$state', '$stateParams', '$history', 'UserService', 'OrmPassport', function ($q, $scope, $rootScope, $state, $stateParams, $history, UserService, OrmPassport) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];

	$scope.partnerTypeEntity = false;
	$scope.user = {
		email: undefined,
		rememberMe: true,
		password: undefined,
		password2: undefined
	};

	$scope.passportList = OrmPassport.find({
		filter: {
			order: 'priority ASC',
			include: 'primaryImage'
		}
	});

	$scope.login = function () {
		var promise = $q(function (resolve, reject) {
			(function () {
				if (!$scope.user.email) {
					return $q.reject(new Error('Введите адрес электронной почты'));
				}
				if (!$scope.user.password) {
					return $q.reject(new Error('Введите пароль'));
				}
				return UserService.login($scope.user.email, $scope.user.password, $scope.user.rememberMe);
			})().then(function () {
				$scope.user.email = undefined;
				$scope.user.password = undefined;
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.register = function (anonymous) {
		var promise = $q(function (resolve, reject) {
			$q.resolve().then(function () {
				if (!anonymous) {
					if (!$scope.user.password || !$scope.user.password2 || !$scope.user.email || !$scope.user.firstName || !$scope.user.lastName) {
						return $q.reject(new Error('Заполните обязательные поля!'));
					}
					if ($scope.user.password !== $scope.user.password2) {
						return $q.reject(new Error('Пароли не совпадают!'));
					}
				} else {
					$scope.user.email = undefined;
					$scope.user.password = undefined;
					$scope.user.firstName = undefined;
					$scope.user.lastName = undefined;
				}

				var partnerTypeId = $scope.partnerTypeEntity ? 'partner_types.entity' : 'partner_types.individual';
				return UserService.register($scope.user.email, $scope.user.password, $scope.user.firstName, $scope.user.lastName, partnerTypeId, anonymous);
			}).then(function (result) {
				if (result && !result.anonymous) {
					$rootScope.$broadcast('notification', {
						type: 'success',
						message: 'На Ваш E-mail выслано письмо с cсылкой, для активации вашего аккуанта'
					});
				}
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.logout = function () {
		var promise = $q(function (resolve, reject) {
			(function () {
				if (!$rootScope.currentUser) {
					return $q.reject(new Error('Вы не авторизованы'));
				}
				return UserService.logout();
			})().then(function () {
				$rootScope.$broadcast('notification', {
					type: 'success',
					message: 'Вы успешно вышли из системы!'
				});
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.resetPassword = function () {
		var promise = $q(function (resolve, reject) {
			UserService.resetPassword($scope.user.email).then(function () {
				$rootScope.$broadcast('notification', {
					type: 'success',
					message: 'Письмо с инструкциями по сбросу пароля отправлено на указанную почту'
				});
				$state.go('user.reset-password.request.success');
				return resolve() || null;
			}).catch(function (err) {
				$rootScope.$broadcast('notification', {
					type: 'error',
					message: err
				});
				return reject(err);
			});
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.confirmResetPassword = function () {
		var promise = $q(function (resolve, reject) {
			if ($scope.user.password !== $scope.user.password2) {
				$rootScope.$broadcast('notification', {
					type: 'warning',
					message: 'Пароли не совпадают',
					ttl: 2000
				});
				return reject();
			}
			if ($stateParams.accessToken && $stateParams.userId) {
				UserService.confirmResetPassword($stateParams.userId, $stateParams.accessToken, $scope.user.password).then(function () {
					$rootScope.$broadcast('notification', {
						type: 'success',
						message: 'Пароль изменен'
					});
					$state.go('user.reset-password.success');
					return resolve() || null;
				}).catch(function (err) {
					$rootScope.$broadcast('notification', {
						type: 'error',
						message: err
					});
					return reject(err);
				});
			} else {
				$state.go('index');
				return resolve();
			}
		});
		$scope.promiseList.push(promise);
		return promise;
	};

	$scope.$on('login', function () {
		if ($history.all() <= 1) {
			$state.go('index');
		} else {
			$history.back();
		}
	});

	$scope.$on('register', function () {
		if ($history.all() <= 1) {
			$state.go('index');
		} else {
			$history.back();
		}
	});
}]);