'use strict';

function isIE() {
	var userAgent = window.navigator.userAgent;

	var msie = userAgent.indexOf('MSIE');
	if (msie > 0) {
		return true;
	}

	var trident = userAgent.indexOf('Trident');
	if (trident > 0) {
		return true;
	}

	var edge = userAgent.indexOf('Edge');
	if (edge > 0) {
		return true;
	}

	return false;
}

(function () {
	var DIRECTIVE_NAME = 'nwProductImage';

	angular.module('app').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				var product = void 0;
				var index = 0;
				var size = 'large';

				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];
				// Определить, что в значении атрибута - объект с параметрами или строка, описывающая объект товара в scope.
				var isObject = attributeValue.indexOf('{') !== -1;
				if (isObject) {
					var properties = scope.$eval(attributeValue);

					// Если объект успешно расчитан,
					if (properties) {
						// получить из него продукт и индекс необходимого изображения.
						product = properties.product;
						index = properties.index || index;
						size = properties.size || size;
					} else {
						// Если не удалось распарсить объект из строки, значит в значении атрибута опечатка.
						throw new Error(DIRECTIVE_NAME + ': в значении атрибута опечатка. Не удаётся вычислить значение атрибута как объект. Значение атрибута: ' + attributeValue);
					}
				} else {
					product = scope.$eval(attributeValue);
				}

				// Если объект товара не найден, значит в значении атрибута опечатка в имени переменной scope, которая должна содержать объект товара.
				if (!product) {
					throw new Error(DIRECTIVE_NAME + ': в значении атрибута опечатка. Не удаётся найти объект товара в указанной переменной в scope. Значение атрибута: ' + attributeValue);
				} else if (!angular.isObject(product)) {
					throw new Error(DIRECTIVE_NAME + ': значение, полученное из scope из переменной ' + attributeValue + ' не является объектом.');
				}

				(function () {
					// Использовать объект товара при получении для формирования атрибутов тега изображения.
					if (product.$resolve || !product.$promise) {
						return Promise.resolve();
					} else {
						return product.$promise;
					}
				})().then(function (result) {
					// Функция обработки объекта товара для генерации атрибутов тега изображения, на который добавлена данная директива.
					var image = void 0;
					if (!image && product.images && product.images.length > 0) {
						image = product.images[index];
					}
					if (!image && (product.primaryImage || product.primaryImageId)) {
						image = product.primaryImage || {
							id: product.primaryImageId
						};
					}

					var imageUrl = void 0;
					if (image) {
						imageUrl = '/api/images/' + image.id + '/' + (size ? size : 'large') + '/download';
					} else {
						image = function () {
							return product.elementType === 0 ? {
								filename: 'default_element.jpg',
								description: 'Нет изображения'
							} : {
								filename: 'default_group.jpg',
								description: 'Нет изображения'
							};
						}();

						imageUrl = '/api/containers/productImages/download/' + image.filename;
					}

					var styleAttr = element.attr('style') || '';
					if (styleAttr.length > 0) {
						styleAttr += ';';
					}

					if (isIE()) {
						styleAttr += 'display: inline-block;';
						styleAttr += 'background-image:url(\'' + imageUrl + '\');';
						styleAttr += 'background-position:center center;';
						styleAttr += 'background-repeat:no-repeat;';
						styleAttr += 'background-size:contain;';
						styleAttr += 'width:100%;';
						styleAttr += 'height:100%;';
					} else {
						styleAttr += 'display: block;';
						if (image.preview) {
							styleAttr += 'background:url(data:image/jpeg;base64,' + image.preview + ');';
							styleAttr += 'filter:blur(0px);';
							styleAttr += 'background-size:100%;';
						}
						styleAttr += 'display:block;';
						styleAttr += 'max-width:100%;';
						attrs.$set('src', imageUrl);
					}
					element.attr('style', styleAttr);
					if (image.description) {
						element.attr('alt', image.description);
					}
				}).catch(function (err) {
					throw err;
				});
			}
		};
	});
})();