'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	angular.module('natiwi.navigation-menu').factory('menuAim', [function () {
		var MOUSE_LOCS_TRACKED = 3;
		var DELAY = 300;

		var $menu = null,
		    activeRow = null,
		    mouseLocs = [],
		    lastDelayLoc = null,
		    timeoutId = null,
		    options = null;

		var mousemoveDocument = function mousemoveDocument(e) {
			mouseLocs.push({
				x: e.pageX,
				y: e.pageY
			});

			if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
				mouseLocs.shift();
			}
		};

		var mouseleaveMenu = function mouseleaveMenu() {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			if (options.exitMenu(this)) {
				if (activeRow) {
					options.deactivate(activeRow);
				}

				activeRow = null;
			}
		};

		var mouseenterRow = function mouseenterRow() {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			options.enter(this);
			possiblyActivate(this);
		};

		var mouseleaveRow = function mouseleaveRow() {
			options.exit(this);
		};

		var clickRow = function clickRow() {
			activate(this);
		};

		var activate = function activate(row) {
			if (row == activeRow) {
				return;
			}

			if (activeRow) {
				options.deactivate(activeRow);
			}

			options.activate(row);
			activeRow = row;
		};

		var possiblyActivate = function possiblyActivate(row) {
			var delay = activationDelay();

			if (delay) {
				timeoutId = setTimeout(function () {
					possiblyActivate(row);
				}, delay);
			} else {
				activate(row);
			}
		};

		var activationDelay = function activationDelay() {
			if (!activeRow || !$(activeRow).is(options.submenuSelector)) {
				return 0;
			}

			var offset = $menu.offset(),
			    upperLeft = {
				x: offset.left,
				y: offset.top - options.tolerance
			},
			    upperRight = {
				x: offset.left + $menu.outerWidth(),
				y: upperLeft.y
			},
			    lowerLeft = {
				x: offset.left,
				y: offset.top + $menu.outerHeight() + options.tolerance
			},
			    lowerRight = {
				x: offset.left + $menu.outerWidth(),
				y: lowerLeft.y
			},
			    loc = mouseLocs[mouseLocs.length - 1],
			    prevLoc = mouseLocs[0];

			if (!loc) {
				return 0;
			}

			if (!prevLoc) {
				prevLoc = loc;
			}

			if (prevLoc.x < offset.left || prevLoc.x > lowerRight.x || prevLoc.y < offset.top || prevLoc.y > lowerRight.y) {
				return 0;
			}

			if (lastDelayLoc && loc.x == lastDelayLoc.x && loc.y == lastDelayLoc.y) {
				return 0;
			}

			function slope(a, b) {
				return (b.y - a.y) / (b.x - a.x);
			};

			var decreasingCorner = upperRight,
			    increasingCorner = lowerRight;

			if (options.submenuDirection == 'left') {
				decreasingCorner = lowerLeft;
				increasingCorner = upperLeft;
			} else if (options.submenuDirection == 'below') {
				decreasingCorner = lowerRight;
				increasingCorner = lowerLeft;
			} else if (options.submenuDirection == 'above') {
				decreasingCorner = upperLeft;
				increasingCorner = upperRight;
			}

			var decreasingSlope = slope(loc, decreasingCorner),
			    increasingSlope = slope(loc, increasingCorner),
			    prevDecreasingSlope = slope(prevLoc, decreasingCorner),
			    prevIncreasingSlope = slope(prevLoc, increasingCorner);

			if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
				lastDelayLoc = loc;
				return DELAY;
			}

			lastDelayLoc = null;
			return 0;
		};

		var init = function init(opts) {
			options = angular.extend({
				rowSelector: '> li',
				submenuSelector: '*',
				submenuDirection: 'right"=',
				tolerance: 75,
				enter: angular.noop,
				exit: angular.noop,
				activate: angular.noop,
				deactivate: angular.noop,
				exitMenu: angular.noop
			}, opts);
		};

		var registerMenu = function registerMenu(menu) {
			$menu = menu;
			$menu.mouseleave(mouseleaveMenu);
		};

		var registerRow = function registerRow(row) {
			row.mouseenter(mouseenterRow).mouseleave(mouseleaveRow).click(clickRow);
		};

		$(document).mousemove(mousemoveDocument);

		return {
			init: init,
			registerMenu: registerMenu,
			registerRow: registerRow
		};
	}]).directive('nwNavMenu', ['cdn', function (cdn) {
		return {
			restrict: 'E',
			scope: {
				visible: '=?',
				tolerance: '=?',
				direction: '=?',
				enter: '=?',
				exit: '=?',
				activate: '=?',
				deactivate: '=?',
				exitmenu: '=?exitmenu',
				selector: '@?',
				static: '=?'
			},
			transclude: {
				trigger: '?nwNavTrigger',
				content: 'nwNavContent'
			},
			replace: true,
			templateUrl: cdn.url + 'core/shared/nw-navigation-menu/nw-navigation-menu.template.html',
			controllerAs: 'navMenuCtrl',
			controller: ['$element', '$scope', 'menuAim', function ($element, $scope, menuAim) {
				var vm = this;

				menuAim.init({
					rowSelector: '> li',
					submenuSelector: $scope.selector || '*',
					submenuDirection: $scope.direction || 'left',
					tolerance: $scope.tolerance || 75,
					enter: $scope.enter || angular.noop,
					exit: $scope.exit || angular.noop,
					activate: activate,
					deactivate: deactivate,
					exitMenu: exitMenu
				});

				function activate(row) {
					$(row).children().addClass('is-active').removeClass('fade-out');
					if ($('.cd-dropdown-content .fade-in').length == 0) {
						$(row).children('ul').addClass('fade-in');
					}
					$scope.activate && $scope.activate();
				}

				function deactivate(row) {
					$(row).children().removeClass('is-active');
					if ($('li.has-children:hover').length == 0 || $('li.has-children:hover').is($(row))) {
						$('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
						$(row).children('ul').addClass('fade-out');
					}
					$scope.deactivate && $scope.deactivate();
				}

				function exitMenu() {
					$('.cd-dropdown-content').find('.is-active').removeClass('is-active');
					$scope.visible = false;
					$scope.$apply();
					$scope.exitmenu && $scope.exitmenu();
					return true;
				}

				$(document).on('click', '.has-children a', function (event) {
					//prevent default clicking on direct children of .has-children
					event.preventDefault();
					$(this).next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('move-out');

					$('.cd-dropdown-content').animate({
						scrollTop: 0
					}, 'fast');
				});

				//submenu items - go back link
				$(document).on('click', '.go-back', function () {
					var selected = $(this),
					    visibleNav = $(this).parent('ul').parent('.has-children').parent('ul');
					selected.parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('move-out');
				});

				$(document).on('click', '.cd-dropdown .cd-toggle', function () {
					vm.toggleMenu();
				});

				vm.closeMenu = function () {
					$scope.visible = false;
				};

				vm.openMenu = function () {
					$('.has-children ul').addClass('is-hidden');
					$('.move-out').removeClass('move-out');
					$('.is-active').removeClass('is-active');
					$scope.visible = true;
				};

				vm.toggleMenu = function () {
					$scope.visible ? vm.closeMenu() : vm.openMenu();
				};

				$scope.toggleMenu = function () {
					$scope.visible ? vm.closeMenu() : vm.openMenu();
				};

				vm.isVisible = function () {
					return $scope.visible || false;
				};

				return vm;
			}]
		};
	}]);
})();