'use strict';

angular.module('app').controller('PartnerContactListController', ['$scope', '$q', 'OrmPartnerContactSet', function ($scope, $q, OrmPartnerContactSet) {
	// Список promise используемый для отображения загрузчика
	$scope.promiseList = [];
	$scope.contactList = undefined;
	$scope.contactListTotalCount = 0;

	var configure = function configure() {
		return $q(function (resolve, reject) {
			OrmPartnerContactSet.viewList({
				filter: {
					include: 'contacts'
				}
			}).$promise.then(function (result) {
				var output = result && result.output || [];
				var outputInfo = result && result.outputInfo || {
					totalCount: output.length
				};

				$scope.contactList = output;
				$scope.contactListTotalCount = outputInfo.totalCount || 0;
				return resolve() || null;
			}).catch(function (err) {
				return reject(err);
			});
		});
	};

	$scope.removeItem = function (contactSet) {
		$scope.promiseList.push($q(function (resolve, reject) {
			if (!contactSet) {
				return reject(new Error('Набор контактной информации пуст'));
			}
			OrmPartnerContactSet.makeDeleted({
				mode: true
			}, {
				id: contactSet.id
			}).$promise.then(function (result) {
				return configure();
			}).then(function (result) {
				return resolve() || null;
			}).catch(function (err) {
				return reject(err);
			});
		}));
	};

	$scope.promiseList.push(configure());
}]);