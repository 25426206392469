'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwFormatAsPrice';

	angular.module('app').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];

				scope.$watch(attributeValue, function (newValue) {
					if (newValue !== undefined) {
						if (angular.isNumber(newValue) || angular.isString(newValue)) {
							// Обычное отображение даты в указанном формате.
							var result = (Math.round(newValue * 100) / 100).toFixed(2) + ' руб.';

							element.text(result);
						} else {
							throw new Error(DIRECTIVE_NAME + ': значение, полученное из scope из переменной ' + attributeValue + ' не является ни числом, ни строкой.');
						}
					}
				});
			}
		};
	});
})();