'use strict';

(function () {
	var DIRECTIVE_NAME = 'nwProductPrice';

	angular.module('app').directive(DIRECTIVE_NAME, function () {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				// Получить значение атрибута, к которому привязана директива.
				var attributeValue = attrs[DIRECTIVE_NAME];
				// Получить объект товара из переменной scope с указанным в значении атрибута именем.
				var product = scope.$eval(attributeValue);

				var format = function format(product) {
					var price = product.price || product.prices && product.prices.length && product.prices[0].price || null;
					if (price && price > 0) {
						element.text((Math.round(price * 100) / 100).toFixed(2) + ' руб.');
					} else {
						element.text('Нет цены');
					}
				};

				// Если объект товара не найден, значит в значении атрибута опечатка в имени переменной scope, которая должна содержать объект товара.
				if (!product) {
					throw new Error(DIRECTIVE_NAME + ': в значении атрибута опечатка. Не удаётся найти объект товара в указанной переменной в scope. Значение атрибута: ' + attributeValue);
				} else if (!angular.isObject(product)) {
					throw new Error(DIRECTIVE_NAME + ': значение, полученное из scope из переменной ' + attributeValue + ' не является объектом.');
				}

				format(product);

				scope.$watch(attributeValue, function (value) {
					product = value;
					format(product);
				});

				scope.$watch(attributeValue + '.price', function (value) {
					format(product);
				});
			}
		};
	});
})();