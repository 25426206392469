'use strict';

(function () {
	var COMPONENT_NAME = 'nwProductFeatureList';

	angular.module('app').component(COMPONENT_NAME, {
		template: '<div ng-include="getTemplate()">',
		bindings: {
			productItem: '<',
			title: '<',
			onFeatureChange: '=',
			dontUseCdn: '<'
		},
		controller: function controller($scope, $element, $attrs, __, ConstantService, cdn) {
			var ctrl = this;

			var configure = function configure(productItem) {
				ConstantService.fetchConstants(['AllowClientProductInStock', 'AllowOnlyProductInStock']).then(function (result) {
					var AllowClientProductInStock = __.get(result, 'AllowClientProductInStock', false);
					var AllowOnlyProductInStock = __.get(result, 'AllowOnlyProductInStock', false);

					var featureList = productItem.features;
					if (featureList && featureList.length > 0) {
						ctrl.propertiesOptions = {
							value: null,
							values: __.map(featureList, function (value) {
								var hasInStock = true;
								if (AllowClientProductInStock && AllowOnlyProductInStock) {
									hasInStock = __.get(value, 'inStock', 0) > 0;
								}
								return {
									name: function () {
										if (!hasInStock) {
											return value.name + ' (\u043D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438)';
										}
										return value.name;
									}(),
									value: value,
									enabled: hasInStock
								};
							})
						};
					} else {
						ctrl.propertiesOptions = {
							value: null,
							values: [{
								name: 'Недоступно',
								enabled: true,
								value: {
									id: null,
									standart: true,
									name: 'Недоступно'
								}
							}]
						};
					}
					var defaultValue = __.head(ctrl.propertiesOptions.values);
					ctrl.propertiesOptions.value = defaultValue;
					ctrl.onFeatureChangeEvent(defaultValue);
				});
			};
			ctrl.$onChanges = function (changesObj) {
				var productItemChanges = changesObj.productItem;
				if (productItemChanges.currentValue !== productItemChanges.previousValue) {
					var productItem = productItemChanges.currentValue;
					ctrl.productItem = productItem;
					configure(productItem);
				}
			};
			ctrl.onFeatureChangeEvent = function (value) {
				var feature = value.value;
				if (ctrl.onFeatureChange) {
					ctrl.onFeatureChange(feature);
				}
			};

			//поддержка кастомных шаблонов
			$scope.getTemplate = function () {
				var cdnUrl = '';
				if (!ctrl.dontUseCdn) {
					cdnUrl = cdn.url;
				}
				return cdnUrl + 'core/shared/nw-product-feature-list/nw-product-feature-list.template.html';
			};
		}
	});
})();