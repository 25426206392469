'use strict';

angular.module('app').controller('ShoppingCartController', ['$scope', 'ShoppingCartService', function ($scope, ShoppingCartService) {
	$scope.shoppingCart = ShoppingCartService.getCart();

	$scope.increaseElementQuantity = function (r) {
		ShoppingCartService.updateElement(r, {
			quantity: +r.quantity + 1,
			notify: false
		});
	};

	$scope.decreaseElementQuantity = function (r) {
		ShoppingCartService.updateElement(r, {
			quantity: r.quantity > 1 ? +r.quantity - 1 : 1,
			notify: false
		});
	};

	$scope.changeElementQuantity = function (r, quantity) {
		ShoppingCartService.updateElement(r, {
			quantity: quantity > 0 ? +quantity : 1,
			notify: false
		});
	};

	$scope.removeElement = function (r) {
		ShoppingCartService.removeElement(r);
	};

	$scope.removeAll = function () {
		ShoppingCartService.removeAll();
	};
}]);