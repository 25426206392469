'use strict';

(function ($) {
	var DIRECTIVE_NAME = 'imageGallery';

	angular.module(DIRECTIVE_NAME).directive(DIRECTIVE_NAME, ['$timeout', function ($timeout) {
		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				var imagesPath = void 0,
				    images = void 0;

				// Получить элемент, помеченный директивой ng-image-gallery.
				var ngImageGalleryAttributeString = 'data-ng-image-gallery',
				    ngImageGalleryElement = $(element).find('*[' + ngImageGalleryAttributeString + ']');
				if (!ngImageGalleryElement) {
					throw new Error(DIRECTIVE_NAME + ': внутри элемента, помеченного данной директивой, не найден элемент с атрибутом "' + ngImageGalleryAttributeString + '".');
				}
				// Получить путь к изображениям в scope из элемента, помеченного директивой ng-image-gallery.
				imagesPath = ngImageGalleryElement.attr('images');

				// Ожидать получения данных о изображениях для галереи.
				scope.$watch(imagesPath, function (newValue) {
					if (newValue) {
						images = newValue;

						if (images.length) {
							$timeout(function () {
								// Поиск атрибутов основного изображения (области отображения) и второстепенных.
								var viewportElements = $(element).find('div[data-image-gallery-viewport]'),
								    ngImageGalleryElement = $(element).find('*[data-ng-image-gallery]').first(),
								    imagesElements = $(ngImageGalleryElement).find('div.thumb');

								// Валидация получения необходимых элементов страницы.
								if (viewportElements.length === 0) {
									throw new Error(DIRECTIVE_NAME + ': внутри элемента страницы, помеченного данной директивой, не найден ни один div элемент, помеченный директивой "data-image-gallery-viewport". Элемент должен быть именно div.');
								}
								if (ngImageGalleryElement.length === 0) {
									throw new Error(DIRECTIVE_NAME + ': внутри элемента страницы, помеченного данной директивой, не найден элемент, помеченный директивой "data-ng-image-gallery".');
								}
								if (imagesElements.length === 0) {
									throw new Error(DIRECTIVE_NAME + ': внутри элемента страницы, помеченного директивой "data-ng-image-gallery", не найден ни один div с классом "thumb". Возможно на элементе с атрибутом "data-ng-image-gallery" отсутствует атрибут "thumbnails" со значением "true", это обязательно для работы с данной директивой. Возможно также, что используется версия ng-image-gallery, которая использует иную разметку. Директива ' + DIRECTIVE_NAME + ' тестировалась только с версией ng-image-gallery 2.0.9. Если используется более новая версия, необходимо обновить директиву ' + DIRECTIVE_NAME + ', чтобы предусмотреть все варианты; если же на сайте используется более старая версия, установить новую.');
								}

								// Отобразить во всех областях просмотра первое изображение галереи.
								viewportElements.each(function (index, viewportElement) {
									$(viewportElement).css('backgroundImage', $(this).css('backgroundImage'));
									$(viewportElement).click(function () {
										angular.element(ngImageGalleryElement).scope().$eval($(viewportElement).attr('ng-click'));
									});
								});

								// Подставляет для всех областей просмотра стиль фонового изображения и ng-click, соответствующий указанному элементу галереи изображений.
								var updateViewportElements = function updateViewportElements(imageElement) {
									var imageLoaded = $(imageElement).css('backgroundImage') !== 'none';
									if (imageLoaded) {
										// Менять отображаемое в областях просмотра изображение в соответствии с наведением указателя на изображение из галереи.
										$(viewportElements).css('backgroundImage', $(imageElement).css('backgroundImage'));
									} else {
										var imageUrl = $(imageElement).attr('show-image-async');
										var image = new Image();
										image.src = imageUrl;
										image.onload = function () {
											$(viewportElements).css('backgroundImage', 'url(' + imageUrl + ')');
										};
										image.onerror = function (err) {
											$(imageElement).empty(); // remove loading animation element
										};
									}

									// Получить значение атрибута ng-click, и индекс элемента изображения галереи,
									// создать data-ng-click для всех областей просмотра на основе ng-click элемента изображения галереи с его же индексом,
									// этот обработчик будет выполняться в scope ng-image-gallery.
									var imageElementNgClick = $(imageElement).attr('ng-click'),
									    imageElementIndex = angular.element(imageElement).scope().$eval('$index'),
									    viewportElementsNgClick = imageElementNgClick.replace('$index', imageElementIndex);
									$(viewportElements).attr('ng-click', viewportElementsNgClick);
								};

								updateViewportElements(imagesElements[0]);

								// Обработчик события наведения на изображение галереи.
								imagesElements.on({
									mouseenter: function mouseenter() {
										updateViewportElements(this);
									}
								});
							});
						}
					}
				});
			}
		};
	}]);
})(jQuery);