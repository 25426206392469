'use strict';

angular.module('app').controller('ValidatePaymentController', ['$scope', '$state', '$stateParams', '$q', '$timeout', 'OrmPaymentOption', function ($scope, $state, $stateParams, $q, $timeout, OrmPaymentOption) {
	$scope.promiseList = [];

	function configure() {
		return $q(function (resolve, reject) {
			if ($stateParams.operationId) {
				$timeout(function () {
					OrmPaymentOption.validateOperation({
						operationId: $stateParams.operationId
					}, function (result) {
						if (result && result.result) {
							$state.go('process.order.success');
						} else {
							$state.go('process.order.fail');
						}
						return resolve() || null;
					}, function (err) {
						$state.go('process.order.fail');
						return reject(err);
					});
				}, 2000);
			}
			return resolve();
		});
	};

	$scope.promiseList.push(configure());
}]);