'use strict';

// Предоставляет директиву для работы в паре с ng-image-gallery, которая позволяет отображать в одном или нескольких элементах страницы изображение галереи, на которое был наведён указатель.
// Отображение происходит за счёт установки CSS стиля "background-image" для элементов, помеченных атрибутом "data-image-gallery-viewport".
// Так же позволяет открывать просмотр изображения не только по клику на изображение галереи, но и по клику на любую область просмотра (при этом откроется именно то изображение,
// которое отображено в области просмотра), однако, ВНИМАНИЕ: для работы этой функции необходимо, чтобы у элемента с директивой "ng-image-gallery"/элемента "ng-image-gallery"
// был указан атрибут methods со значением "methods", т. е.: methods="methods".
// По опциям для директивы ng-image-gallery см. документацию https://github.com/thatisuday/ng-image-gallery.
// Для использования необходимы атрибуты:
//      data-image-gallery - директива - добавить на элемент, внутри которого должна быть галерея изображений (то есть элемент, помеченный директивой "data-ng-image-gallery") и все элементы, помеченные атрибутом "data-image-gallery-viewport".
//      data-image-gallery-viewport - пометить этим атрибутом div элемент(ы), в котором должно отображаться изображение галереи, на которое последний раз был наведён указатель. Это область просмотра. Она так же по умолчанию отображает первое изображение галереи.
// Внешние зависимости:
//      https://github.com/jquery/jquery (тестировалось с версией 1.11.3)
//      https://github.com/thatisuday/ng-image-gallery (тестировалось с версией 2.0.9)
// Пример использования:
/*
<section data-image-gallery>
	<div data-image-gallery-viewport style="width: 500px; height: 500px;">
	<div data-ng-image-gallery images="images" methods="methods"></div>
</section>
*/

(function () {
	if (!window.jQuery) console.error('image-gallery AngularJS 1 module requires jquery plugin.');

	angular.module('imageGallery', ['thatisuday.ng-image-gallery']);
})();