'use strict';

/* eslint angular/prefer-component:0 */

(function () {
	angular.module('natiwi.navigation-menu').directive('nwNavContent', ['menuAim', function (menuAim) {
		return {
			restrict: 'E',
			transclude: {
				title: '?nwNavTitle'
			},
			// replace: true,
			require: ['^nwNavMenu'],
			// scope: true,
			bindToController: true,
			template: '<ng-transclude data-ng-transclude-slot="title"></ng-transclude>\n\t\t\t\t<a href class="cd-close" data-ng-click="navMenuCtrl.closeMenu()">\u0417\u0430\u043A\u0440\u044B\u0442\u044C</a>\n\t\t\t\t<ul class="cd-dropdown-content" ng-transclude></ul>',
			link: {
				post: function post(scope, element, attrs, ctrls) {
					scope.navMenuCtrl = ctrls && ctrls.length && ctrls[0];
					menuAim.registerMenu(element);
				}
			}
		};
	}]);
})();