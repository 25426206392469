'use strict';

(function () {
	angular.module('app').value('UriConfig', {
		useSlugifyName: false
	}).filter('nwUri', function (UriConfig) {
		return function (input) {
			if (!input) {
				return undefined;
			}
			if (UriConfig.useSlugifyName) {
				var slugufyName = input.slugifyName;
				if (slugufyName) {
					return slugufyName;
				}
			}
			return input.id;
		};
	});
})();