'use strict';

(function () {
	angular.module('app', ['ui.router', 'ngSanitize', 'ngCookies', 'ngMeta', 'lbServices', 'toastr', 'natiwi.core.cdn', 'lodash']).config([function () {
		angular.lowercase = angular.$$lowercase;
	}]).config(['$provide', function ($provide) {
		var PLURAL_CATEGORY = {
			ZERO: 'zero',
			ONE: 'one',
			TWO: 'two',
			FEW: 'few',
			MANY: 'many',
			OTHER: 'other'
		};

		function getDecimals(n) {
			n = n + '';
			var i = n.indexOf('.');
			return i == -1 ? 0 : n.length - i - 1;
		}

		function getVF(n, opt_precision) {
			var v = opt_precision;

			if (undefined === v) {
				v = Math.min(getDecimals(n), 3);
			}

			var base = Math.pow(10, v);
			var f = (n * base | 0) % base;
			return {
				v: v,
				f: f
			};
		}

		$provide.value('$locale', {
			DATETIME_FORMATS: {
				AMPMS: ['ДП', 'ПП'],
				DAY: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
				ERANAMES: ['до Рождества Христова', 'от Рождества Христова'],
				ERAS: ['до н. э.', 'н. э.'],
				FIRSTDAYOFWEEK: 0,
				MONTH: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
				SHORTDAY: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
				SHORTMONTH: ['янв.', 'февр.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
				STANDALONEMONTH: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
				WEEKENDRANGE: [5, 6],
				fullDate: 'EEEE, d MMMM y \'г\'.',
				longDate: 'd MMMM y \'г\'.',
				medium: 'd MMM y \'г\'. H:mm:ss',
				mediumDate: 'd MMM y \'г\'.',
				mediumTime: 'H:mm:ss',
				short: 'dd.MM.y H:mm',
				shortDate: 'dd.MM.y',
				shortTime: 'H:mm'
			},
			NUMBER_FORMATS: {
				CURRENCY_SYM: 'руб.',
				DECIMAL_SEP: ',',
				GROUP_SEP: ' ',
				PATTERNS: [{
					gSize: 3,
					lgSize: 3,
					maxFrac: 3,
					minFrac: 0,
					minInt: 1,
					negPre: '-',
					negSuf: '',
					posPre: '',
					posSuf: ''
				}, {
					gSize: 3,
					lgSize: 3,
					maxFrac: 2,
					minFrac: 2,
					minInt: 1,
					negPre: '-',
					negSuf: ' ¤',
					posPre: '',
					posSuf: ' ¤'
				}]
			},
			id: 'ru-ru',
			localeID: 'ru_RU',
			pluralCat: function pluralCat(n, opt_precision) {
				var i = n | 0;
				var vf = getVF(n, opt_precision);
				if (vf.v == 0 && i % 10 == 1 && i % 100 != 11) {
					return PLURAL_CATEGORY.ONE;
				}
				if (vf.v == 0 && i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 12 || i % 100 > 14)) {
					return PLURAL_CATEGORY.FEW;
				}
				if (vf.v == 0 && i % 10 == 0 || vf.v == 0 && i % 10 >= 5 && i % 10 <= 9 || vf.v == 0 && i % 100 >= 11 && i % 100 <= 14) {
					return PLURAL_CATEGORY.MANY;
				}
				return PLURAL_CATEGORY.OTHER;
			}
		});
	}]).config(['toastrConfig', '$urlMatcherFactoryProvider', function (toastrConfig, $urlMatcherFactoryProvider) {
		angular.extend(toastrConfig, {
			autoDismiss: false,
			containerId: 'toast-container',
			maxOpened: 5,
			newestOnTop: true,
			positionClass: 'toast-top-right',
			preventDuplicates: false,
			preventOpenDuplicates: false,
			target: 'body'
		});

		// Игнорировать слеш в конце URL, если за ним ничего нет.
		$urlMatcherFactoryProvider.strictMode(false);
	}]).config(['$httpProvider', function ($httpProvider) {
		// Добавление перехватчика всех rest вызовов к backend'у
		$httpProvider.interceptors.push(function ($q, $injector) {
			return {
				// Обработка ошибки от backend'а
				responseError: function responseError(rejection) {
					// Если ошибка 401(Не авторизован), то токен доступа не действителен
					if (rejection.status == 401) {
						// Очищаем данные о пользователе
						$injector.get('UserService').clear();
					}
					return $q.reject(rejection);
				}
			};
		});
	}]).config(['uiMask.ConfigProvider', function (uiMaskConfigProvider) {
		uiMaskConfigProvider.clearOnBlur(false);
	}]).value('cgBusyDefaults', {
		message: 'Загрузка...'
	}).run(['UserService', 'ShoppingCartService', 'ConstantService', 'NotificationService', function (UserService, ShoppingCartService, ConstantService, NotificationService) {
		/* eslint angular/di-unused: 0 */
		// Инициализируем сервисы
	}]).run(['$rootScope', '$injector', '$transitions', function ($rootScope, $injector, $transitions) {
		/* eslint angular/no-run-logic: 0 */
		/* eslint angular/on-watch: 0 */
		$transitions.onBefore({
			to: function to(state) {
				return state.data && state.data.authenticationRequired;
			}
		}, function (transition) {
			var currentUser = $rootScope.currentUser;
			// Если состояние, на которое пытается перейти посетитель сайта требует аутентификации и пользователь не залогинен,
			if (!currentUser) {
				return $injector.get('$state').target('user.login', undefined, {
					location: false
				});
			}
		}, {
			priority: 10
		});

		$transitions.onBefore({}, function (transition) {
			// Используется, чтобы уведомить форму товара о том что если открыта галлерея, то её нужно закрыть во избежание ошибок
			$rootScope.$broadcast('onBeforeTransition', {
				transition: transition
			});
		});

		// Обработчик выхода из учётной записи.
		$rootScope.$on('logout', function () {
			// Перезагрузить состояние для инициации обработчика смены состояния, что определён выше.
			// Таким образом при выходе пользователь не останется на странице, которая требует аутентификации.
			$injector.get('$state').reload();
		});
	}]).value('ngMetaTagTemplates', {
		catalogTitleTemplate: 'Купить {{ catalog | nwDisplayName }} – каталог, цены, доставка',
		catalogDescriptionTemplate: 'Широкий ассортимент {{ catalog | nwDisplayName }} лучшие цены в городе, гарантии качества товара',
		productTitleTemplate: '{{ product | nwDisplayName }} – купить, цена, доставка',
		productDescriptionTemplate: 'Купить {{ product | nwDisplayName }} по лучшей цене в городе, быстрая доставка, гарантия на товар',
		newsTitleTemplate: '{{ news.title }}',
		newsDescriptionTemplate: '{{ news.subtitle }}'
	}).run(['ngMeta', function (ngMeta) {
		ngMeta.init();
	}])
	/* .run(['$templateCache', '$http', 'cdn', function($templateCache, $http) {
 	// Фоновое кеширование всех шаблонов.
 			// !!!ToDo: теперь здесь необходимо подгружать только частичные шаблоны, которые используются в директивах и, возможно, будут подключаться через ng-include. А подгрузка template.html-файлов, которые для каждого сайта свои, должна происходить в template/app.js. В ядре будут только template.html-файлы, но для примера интеграции/отладки, их уже не надо подгружать в кэш.
 			// Шаблоны директив.
 			// !!!ToDo: перенести с заменой путей это в template/app.js.
 	// Магазин.
 	$http.get('core/store/products/list/products.list.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/products/list/products.list-grid-view.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/products/list/products.list-list-view.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/products/detail/products.detail.template.html', {
 	    cache: $templateCache
 	});
 	// Компания.
 	$http.get('core/company/news/detail/news.detail.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/company/news/list/news.list.template.html', {
 	    cache: $templateCache
 	});
 	// Оформление заказа.
 	$http.get('core/store/process/order/process.order.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/process/order/success/process.order.success.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/process/order/fail/process.order.fail.template.html', {
 	    cache: $templateCache
 	});
 	// Онлайн оплата.
 	$http.get('core/store/process/payment/resolve/process.payment.resolve.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/store/process/payment/fail/process.payment.fail.template.html', {
 	    cache: $templateCache
 	});
 	// Пользователь.
 	$http.get('core/user/detail/user.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/detail/info/user.info.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/detail/orders/list/user.orders.list.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/detail/orders/detail/user.orders.detail.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/login/user.login.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/register/user.register.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/reset-password/user.reset-password.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/reset-password/request/user.reset-password.request.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/reset-password/request/success/user.reset-password.request.success.template.html', {
 	    cache: $templateCache
 	});
 	$http.get('core/user/reset-password/success/user.reset-password.success.template.html', {
 	    cache: $templateCache
 	});
 	// Шаблоны директив.
 	$http.get('core/shared/nw-pagination/nw-pagination.template.html', {
 	    cache: $templateCache
 	});
 }]) */
	.config(['$stateProvider', '$locationProvider', '$urlRouterProvider', function ($stateProvider, $locationProvider, $urlRouterProvider) {
		// Это состояние для отладки natiwi-cms-client. Это не основное состояние (состояние с url: '/'), в core/app.js вообще нет основного состояния.
		// Основное состояние должно быть добавлено в template/app.js.
		$stateProvider.state({
			name: 'test',
			url: '/test'
		});

		// Пользователь.
		$stateProvider.state({
			name: 'user',
			url: '/user',
			abstract: true,
			templateUrl: 'core/user/detail/user.template.html',
			data: {
				authenticationRequired: true
			}
		});
		$stateProvider.state({
			name: 'user.info',
			url: '',
			views: {
				'@user': {
					templateUrl: 'core/user/detail/info/user.info.template.html',
					controller: function controller($scope, $q, OrmLoyaltyPolicy) {
						// Список promise используемый для отображения загрузчика
						$scope.promiseList = [];

						var configure = function configure() {
							return $q(function (resolve, reject) {
								OrmLoyaltyPolicy.bonusPointBalance().$promise.then(function (result) {
									$scope.bonusPointBalance = result;
									return resolve() || null;
								}).catch(function (err) {
									return reject(err);
								});
							});
						};

						$scope.promiseList.push(configure());
					}
				}
			},
			data: {
				meta: {
					title: 'Профиль',
					description: 'Информация о пользователе'
				}
			}
		});
		$stateProvider.state({
			name: 'user.orders',
			url: '/orders',
			abstract: true,
			template: '<div data-ui-view></div>'
		});
		$stateProvider.state({
			name: 'user.orders.list',
			url: '',
			views: {
				'@user': {
					templateUrl: 'core/user/detail/orders/list/user.orders.list.template.html',
					controller: 'OrderListController'
				}
			},
			data: {
				meta: {
					title: 'Список заказов',
					description: 'Ваши заказы'
				}
			}
		});
		$stateProvider.state({
			name: 'user.orders.detail',
			url: '/{id}',
			views: {
				'@': {
					templateUrl: 'core/user/detail/orders/detail/user.orders.detail.template.html',
					controller: 'OrderDetailController'
				}
			},
			params: {
				id: undefined
			}
		});
		$stateProvider.state({
			name: 'user.register',
			url: '/register',
			views: {
				'@': {
					templateUrl: 'core/user/register/user.register.template.html',
					controller: 'UserController'
				}
			},
			data: {
				authenticationRequired: false,
				meta: {
					title: 'Регистрация',
					description: 'Зарегистрируйтесь на сайте'
				}
			}
		});
		$stateProvider.state({
			name: 'user.login',
			url: '/login',
			views: {
				'@': {
					templateUrl: 'core/user/login/user.login.template.html',
					controller: 'UserController'
				}
			},
			data: {
				authenticationRequired: false,
				meta: {
					title: 'Авторизация',
					description: 'Зайдите на сайт'
				}
			}
		});
		$stateProvider.state({
			name: 'user.logout',
			url: '/logout',
			views: {
				'@': {
					template: '<div></div>',
					controller: function controller($state, UserService) {
						UserService.logout().catch(function (err) {
							$rootScope.$broadcast('notification', {
								type: 'error',
								message: err
							});
						}).finally(function () {
							$state.go('products.list');
						});
					}
				}
			},
			data: {
				meta: {
					title: 'Выйти'
				}
			}
		});
		$stateProvider.state({
			name: 'user.reset-password',
			url: '/reset-password?accessToken&userId',
			views: {
				'@': {
					templateUrl: 'core/user/reset-password/user.reset-password.template.html',
					controller: 'UserController'
				}
			},
			params: {
				accessToken: undefined,
				userId: undefined
			},
			data: {
				authenticationRequired: false,
				meta: {
					title: 'Сброс пароля'
				}
			}
		});
		$stateProvider.state({
			name: 'user.reset-password.request',
			url: '/request',
			views: {
				'@': {
					templateUrl: 'core/user/reset-password/request/user.reset-password.request.template.html',
					controller: 'UserController'
				}
			}
		});
		$stateProvider.state({
			name: 'user.reset-password.request.success',
			url: '/success',
			views: {
				'@': {
					templateUrl: 'core/user/reset-password/request/success/user.reset-password.request.success.template.html'
				}
			}
		});
		$stateProvider.state({
			name: 'user.reset-password.success',
			url: '/success',
			views: {
				'@': {
					templateUrl: 'core/user/reset-password/success/user.reset-password.success.template.html',
					controller: 'UserController'
				}
			},
			data: {
				meta: {
					title: 'Пароль успешно изменен'
				}
			}
		});
		$stateProvider.state({
			name: 'user.contacts',
			url: '/contacts',
			abstract: true,
			data: {
				authenticationRequired: false
			},
			template: '<div data-ui-view></div>'
		});
		$stateProvider.state({
			name: 'user.contacts.list',
			views: {
				'@': {
					templateUrl: 'core/user/contact/list/partner-contact-list.template.html',
					controller: 'PartnerContactListController'
				}
			},
			data: {
				meta: {
					title: 'Контактная информация'
				}
			}
		});
		$stateProvider.state({
			name: 'user.contacts.detail',
			url: '/{id}',
			views: {
				'@': {
					templateUrl: 'core/user/contact/detail/partner-contact-detail.template.html',
					controller: 'PartnerContactDetailController'
				}
			},
			params: {
				id: null
			}
		});
		$stateProvider.state({
			name: 'user.contacts.detail.new',
			url: 'new',
			views: {
				'@': {
					templateUrl: 'core/user/contact/detail/partner-contact-detail.template.html',
					controller: 'PartnerContactDetailController'
				}
			},
			params: {
				id: null
			},
			data: {
				meta: {
					title: 'Новая контактная информация'
				}
			}
		});

		// Магазин.
		$stateProvider.state({
			name: 'products',
			url: '',
			abstract: true,
			template: '<div data-ui-view></div>'
		});
		$stateProvider.state({
			name: 'products.list',
			url: '/catalogs/{group:string}?{page:int}&{limit:int}&sort&{specs:json}&view&q',
			views: {
				'@': {
					templateUrl: 'core/store/products/list/products.list.template.html',
					controller: 'ProductListController'
				}
			},
			params: {
				group: {
					value: null,
					squash: true
				},
				page: undefined,
				limit: {
					dynamic: true
				},
				sort: {
					dynamic: true
				},
				specs: undefined,
				view: undefined,
				q: undefined
			}
		});
		$stateProvider.state({
			name: 'products.detail',
			url: '/products/{id}',
			views: {
				'@': {
					templateUrl: 'core/store/products/detail/products.detail.template.html',
					controller: 'ProductDetailController'
				}
			},
			params: {
				id: undefined
			}
		});
		// Оформление заказа.
		$stateProvider.state('process', {
			url: '/process',
			abstract: true,
			template: '<div data-ui-view></div>',
			data: {
				authenticationRequired: false
			}
		});
		$stateProvider.state('process.order', {
			url: '/order?productItemList&coupon',
			views: {
				'@': {
					templateUrl: 'core/store/process/order/process.order.template.html',
					controller: 'ProcessOrderController'
				}
			},
			resolve: {
				externalOrder: function externalOrder($rootScope, $q, $stateParams, __, ShoppingCartService) {
					return $q(function (resolve, reject) {
						if (!$stateParams.productItemList.length) {
							return resolve();
						}
						__.chain($stateParams.productItemList).filter(function (value) {
							return value.productId;
						}).map(function (value) {
							return {
								product: {
									id: value.productId
								},
								feature: function () {
									return value.featureId ? {
										id: value.featureId
									} : undefined;
								}(),
								quantity: value.quantity || 1,
								notify: false,
								force: true
							};
						}).forEach(ShoppingCartService.createOrUpdateElement).value();
						ShoppingCartService.fetchCart().then(resolve).catch(function (err) {
							$rootScope.$broadcast('notification', {
								type: 'error',
								message: err
							});
							return reject(err);
						});
					});
				}
			},
			params: {
				contactSet: undefined,
				name: undefined,
				phone: undefined,
				coupon: undefined,
				productItemList: {
					type: 'json',
					array: true,
					value: []
				}
			},
			data: {
				authenticationRequired: false,
				meta: {
					title: 'Оформление заказа'
				}
			}
		});
		$stateProvider.state('process.order.success', {
			url: '/success',
			views: {
				'@': {
					templateUrl: 'core/store/process/order/success/process.order.success.template.html',
					controller: function controller($state) {
						setTimeout(function () {
							var currentState = $state.current;
							if ((currentState && currentState.name) === 'process.order.success') {
								$state.go('products.list');
							}
						}, 5000);
					}
				}
			},
			data: {
				meta: {
					title: 'Заказ успешно оформлен'
				}
			}
		});
		$stateProvider.state('process.order.fail', {
			url: '/fail',
			views: {
				'@': {
					templateUrl: 'core/store/process/order/fail/process.order.fail.template.html'
				}
			},
			data: {
				meta: {
					title: 'Во время оформления заказа произошла ошибка'
				}
			}
		});

		// Онлайн оплата.
		$stateProvider.state({
			name: 'process.payment',
			url: '/payment',
			abstract: true,
			data: {
				authenticationRequired: false
			}
		});
		$stateProvider.state({
			name: 'process.payment.validate',
			url: '/validate?operationId',
			views: {
				'@': {
					templateUrl: 'core/store/process/payment/validate.payment.template.html',
					controller: 'ValidatePaymentController'
				}
			},
			params: {
				operationId: undefined
			},
			data: {
				meta: {
					title: 'Проверка оплаты'
				}
			}
		});
		// Новости.
		$stateProvider.state({
			name: 'news',
			url: '',
			abstract: true,
			template: '<div data-ui-view></div>'
		});
		$stateProvider.state({
			name: 'news.list',
			url: '/blog?{page:int}&{limit:int}&sort&q',
			views: {
				'@': {
					templateUrl: 'core/company/news/list/news.list.template.html',
					controller: 'NewsListController'
				}
			},
			params: {
				page: undefined,
				limit: {
					dynamic: true
				},
				sort: {
					dynamic: true
				},
				q: undefined
			},
			data: {
				meta: {
					title: 'Новости'
				}
			}
		});
		$stateProvider.state({
			name: 'news.detail',
			url: '/news/{id}',
			views: {
				'@': {
					templateUrl: 'core/company/news/detail/news.detail.template.html',
					controller: 'NewsDetailController'
				}
			},
			params: {
				id: undefined
			}
		});
		// Это состояние для отладки natiwi-cms-client. Это не основное состояние (состояние с url: '/'), в core/app.js вообще нет основного состояния.
		// Основное состояние должно быть добавлено в template/app.js.
		$stateProvider.state({
			name: '404',
			url: '/404',
			views: {
				'@': {
					templateUrl: 'core/other/error404.template.html',
					controller: 'Error404Controller'
				}
			},
			params: {
				errorUrl: undefined
			}
		});

		// Чтобы использовать html5Mode для новых браузеров,
		// необходимо переписывать ссылки на серверной стороне.
		// Без этого в случае перехода по ссылке на конкретное состояние,
		// а не в корень сайта, будет ошибка 404. Настройка сервера:
		// https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-configure-your-server-to-work-with-html5mode
		// https://docs.angularjs.org/guide/$location
		$locationProvider.html5Mode({
			enabled: false
		}).hashPrefix('!'); // Использовать hashbang. Нужно для SEO (в частности для Яндекс-ботов, чтобы определять, что страница имеет динамически генерируемый контент).

		$urlRouterProvider.otherwise('/404/');
	}]);
})();